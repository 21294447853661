import { db } from "../base";
import { useState, useEffect } from "react";
import useCurrentUser from "./useCurrentUser";
import { useHistory } from "react-router-dom";
import { addWarningNotification } from "../Notifications";

export function useAllProducts() {
  const [products, setProducts] = useState([]);
  const currentUser = useCurrentUser();

  const getProducts = async () => {
    const productsDone = {};
    const productsNeedPay = {};
    if (currentUser) {
      const productUser = await db
        .collection("users")
        .doc(currentUser.id)
        .collection("products")
        .get();

      productUser.forEach((item) => {
        if (item && (item.data().result || item.data().status == "processing"))
          productsDone[item.id] = true;
        if (item && item.data().status == "need-pay")
          productsNeedPay[item.id] = true;
      });
    }
    db.collection("products")
      .get()
      .then((querySnapshot) => {
        let result = [];
        querySnapshot.forEach((doc) =>
          result.push({
            ...doc.data(),
            id: doc.id,
            done: !!productsDone[doc.id],
            needPay: !!productsNeedPay[doc.id],
          })
        );

        setProducts(result.sort((a, b) => a.sort - b.sort));
      });
  };
  useEffect(() => {
    getProducts();
  }, [currentUser]);
  return products;
}

export function useSetPayment(productId) {
  const currentUser = useCurrentUser();

  return (token, amount, cupom) => {
    if (cupom) {
      const updateCupom = async () => {
        const _cupom = await db
          .collection("cupons")
          .doc(cupom)
          .get()
          .then((i) => i.data());

        if (_cupom) {
          db.collection("cupons").doc(cupom).collection("uses").add({
            used: true,
          });
          // .update({
          //   used: (_cupom.used || 0) + 1,
          // });
        }
      };
      updateCupom();
    }
    const userProduct = db
      .collection("users")
      .doc(currentUser.id)
      .collection("products")
      .doc(productId);
    let text = `**Análise de coloração feita**

Id: ${currentUser.id}
Name: ${currentUser.displayName}
Gênero: ${currentUser.gender}
Celular: ${currentUser.cellphone}
Instagram: ${currentUser.instagram}
`;
    fetch(
      "https://discord.com/api/webhooks/811274682052378645/IQJRJ9aYBQM8SvwU9iKrP1HHk67SZtRtLvRH_UWwKwiCSiaKLzgayu5kCgveGVVHUK4O",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: "MF Consultoria",
          avatar_url: "",
          content: text,
        }),
      }
    );
    userProduct.update({
      status: "processing",
      paymentToken: token,
      amount,
      cupom,
    });

    // to do - captura
  };
}

export function useProduct(productId) {
  const currentUser = useCurrentUser();
  const [product, setProduct] = useState();
  const getProduct = async () => {
    const product = await db.collection("products").doc(productId).get();
    const productData = product.data();

    let hasPrerequisite = false;
    if (productData.prerequisite) {
      const productUser = (
        await db
          .collection("users")
          .doc(currentUser.id)
          .collection("products")
          .doc(productData.prerequisite.productId)
          .get()
      ).data();

      hasPrerequisite = !productUser || !productUser.result;
    }

    setProduct({
      id: productId,
      ...productData,
      hasPrerequisite,
    });
  };
  useEffect(() => {
    getProduct();
  }, [productId]);
  return product;
}

export function useStartProduct(productId, introductionDone = false) {
  const currentUser = useCurrentUser();
  const history = useHistory();
  return async () => {
    if (!currentUser) {
      history.push("/login");
      addWarningNotification(
        "Atenção",
        "Você precisa estar logado para iniciar a análise."
      );
      return;
    }

    const product = await db
      .collection("users")
      .doc(currentUser.id)
      .collection("products")
      .doc(productId);

    const questions = await db
      .collection("products")
      .doc(productId)
      .collection("questions")
      .get();

    const productInfo = (
      await db.collection("products").doc(productId).get()
    ).data();

    const newQuestions = [];
    questions.forEach((item) =>
      newQuestions.push({
        id: item.id,
        ...item.data(),
      })
    );
    const procutUser = await product.get();
    if (procutUser && procutUser.data() && procutUser.data().result) {
      history.push(`/quiz/${productId}/finish`);
      return;
    }
    if (procutUser && procutUser.data() && procutUser.data().status == "paid") {
      history.push(`/quiz/${productId}/finish`);
      return;
    }
    if (
      procutUser &&
      procutUser.data() &&
      procutUser.data().status == "processing"
    ) {
      history.push(`/quiz/${productId}/finish`);
      return;
    }

    if (procutUser)
      product.set({
        result: null,
        questions: newQuestions
          .sort((a, b) => a.sort - b.sort)
          .map((item) => item.id)
          .map((id) => ({ id, value: null })),
      });

    if (productInfo.intro && !introductionDone)
      history.push("/quiz/" + productId + "/intro");
    else history.push("/quiz/" + productId + "/start");
  };
}

export function useCurrentQuestion(productId) {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const [question, setCurrentQuestion] = useState();
  const start = useStartProduct(productId, false);
  const getCurrentQuestion = async () => {
    const product = await db
      .collection("users")
      .doc(currentUser.id)
      .collection("products")
      .doc(productId)
      .get();

    const userProduct = product.data();
    let q;
    if (userProduct) {
      q = userProduct.questions.find((q) => q.value == null);
      setCurrentQuestion(q);
    } else {
      start();
    }

    if (!q) history.push(`/quiz/${productId}/finish`);
  };

  useEffect(() => {
    getCurrentQuestion();
  }, []);

  return question;
}
