import { db } from "../base";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../components/auth/Auth";

export function useSiteConfig() {
  const { appConfig } = useContext(AuthContext);

  return appConfig;
}
export function useSiteConfigReload() {
  const { reload } = useContext(AuthContext);

  return reload;
}
