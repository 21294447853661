import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Admin from "./products/Admin";
import Users from "./users/index";
import Backup from "./Backup";

export default () => {
  return (
    <>
      {/* <Backup /> */}
      <Switch>
        <Route path="/adm/users" component={Users} />
        <Route path="/adm/:productId" component={Admin} />
      </Switch>
    </>
  );
};
