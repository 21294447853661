import React, { useEffect, useState } from "react";
import app, { db } from "../../base.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [id, setId] = useState(0);
  const [pending, setPending] = useState(true);
  const [appConfig, setAppConfig] = useState({
    "testimony-name-3": "-  Ianarã Bernardino",
    "info-cards-title-description":
      " Todos nós merecemos entender o que nos *valoriza*, aprender a fazer novas combinações, comprar com assertividade e sentir *segurança e autoestima*!",
    "testimony-name-1": "- Júlia Siqueira",
    "testimony-description-3":
      '"Por trabalhar na área de moda eu já conhecia porém nunca havia feito Análise de Coloração Pessoa. Tive interesse em contratar o serviço para entender melhor as cores que me favorecem e melhorar as minhas escolhas na hora de me vestir. Informação nunca é demais, né? Escolhi a Maysa para fazer a minha análise porque além de ser uma consultora que conheço e confio é uma pessoa muito atenciosa e profissional. Além das cores, ainda recebi propostas de combinações, de tons de cabelo, maquiagem e acessórios que me favorecem. Os resultados foram acima do que eu esperava, agora minha cartela Outono Escuro mora na minha bolsa e eu recomendo a Análise de Coloração Pessoal para todos!"',
    "call-to-action-button": "Quero me cadastrar",
    "info-description-1":
      "Sabe aquelas roupas e acessórios que não te valorizam? Cores que te apagam? Novo tom de cabelo ou maquiagem que não caiu bem? Tudo isso pode ser resolvido com a sua cartela de cores. Descubra quais são as cores e tonalidades que mais te valorizam!\n",
    "call-to-action-product":
      "Você ainda *não iniciou* a sua Análise de {{name}}!",
    "info-2": "Análise de Estilo Pessoal",
    "profile-tab-1": "Dados pessoais",
    "info-4": "Guia de Combinações",
    "call-to-action-home-2":
      "Quer saber mais sobre seu estilo pessoal e como posso te ajudar no *seu autoconhecimento*? Clique aqui e converse comigo!",
    "info-description-3":
      " Quais peças e modelagens valorizam o seu corpo? Conhecer o formato e estrutura do seu corpo é essencial para escolher a modelagem correta, as combinações que te valorizam e criar uma imagem harmônica.",
    "profile-tab-3": "Minha coloração pessoal",
    admins: [],
    "info-description-5":
      "Um guarda-roupa cheio nem sempre significa muitas opções para vestir. Por isso, é importante fazer uma avaliação consciente do que permanece e o que será descartado, de acordo com as análises de estilo, cor e corporal. ",
    "info-5": "Closet Detox",
    "info-6": "Personal Shopper",
    "info-description-2":
      "O seu estilo representa sua personalidade, essência e a mensagem que deseja transmitir ao mundo. Sabendo o seu estilo, não passará mais horas na frente do guarda-roupa com dúvidas sobre o que vestir, nem se sentirá desconfortável com uma escolha mal feita.",
    "home-text-1":
      "*Sinta-se confiante* em ter a sua imagem coerente com o que você quer projetar, sabendo que cada item do seu guarda-roupa tem o *seu estilo te valoriza*.",
    "info-cards-title": "Como a consultoria te ajudará",
    "help-description":
      "Mande suas dúvidas, sugestões, feedbacks para consultoriamayfaggion@gmail.com. Te respondo rápido :)",
    "testimony-name-2": "- Gabriel Sina",
    "products-cards-description-2":
      "Sou *Consultora de Moda*, graduada e mestranda na Universidade de São Paulo (USP).\n\\n\\n\nA consultoria engloba diferentes etapas, que serão disponibilizadas aos poucos por aqui, *100% online*. Você já pode fazer agora mesmo a sua *Análise de Estilo e Análise de Coloração*.",
    "products-cards-description":
      "Todos nós merecemos entender o que nos *valoriza*, aprender a fazer novas combinações, comprar com assertividade e sentir *segurança e autoestima*! \\n\\nPor isso, com essa nova plataforma, você vai *economizar tempo e dinheiro* entrando numa jornada de autoconhecimento, junto comigo.",
    "info-description-6":
      "Economize tempo e dinheiro com compras certeiras. Nem sempre conseguimos diferenciar o que precisamos do que desejamos. Por isso, com a avaliação de uma consultora, são selecionadas peças fundamentais para o seu guarda-roupa.",
    "testimony-description-1":
      '"Os serviços de consultoria e análise pessoais são, antes de mais nada, um exercício de autoconhecimento! Quando feitos por uma profissional competente como a Maysa, resulta em economia, bom gosto e sustentabilidade dentro do próprio guarda roupa. Muitas vezes olhando as nossas peças no dia a dia não temos nem ideia das incríveis combinações que podem sair de lá!"',
    "testimony-description-2":
      '"A consultoria de estilo com a Maysa deixou muito mais claro para mim como escolher e compor roupas que favorecem a forma como gosto de me vestir. Desde então faço compras muito mais conscientes e acertadas, além de ter tornado mais fácil me desapegar e doar o que não se encaixa mais."',
    "info-1": "Análise de Coloração Pessoal",
    "profile-tab-2": "Meu estilo",
    "products-cards-title": "Sobre a Consultora",
    "help-instagram-title": "*Acompanhe meu conteúdo!*",
    "call-to-action-description":
      "Comece *agora* a sua jornada de *autoconhecimento*, junto comigo!",
    "info-description-4":
      "Economize tempo e saiba o que vestir em diversas ocasiões. Montamos um guia ideal para o seu estilo, coloração e modelagem, com roupas do seu guarda-roupa! Afinal, todo guarda-roupa tem seu potencial.",
    "info-3": "Análise Corporal",
    "call-to-action-home":
      "Sinta-se *confiante* de que cada item do seu guarda-roupa é fiel ao *seu estilo*!",
    "help-instagram-description":
      "Estou sempre postando conteúdos novos no meu Instagram sobre Moda e Estilo. Vem me seguir! \\n*@mayfaggion.*",
    "help-title": "Converse comigo!",
  });

  const reload = () => {
    setId((prev) => prev + 1);
  };

  useEffect(() => {
    const loadUser = new Promise((r) =>
      app.auth().onAuthStateChanged((user) => {
        if (user) {
          const userRef = db.collection("users").doc(user.uid);

          db.collection("users")
            .doc(user.uid)
            .get()
            .then((userDb) => {
              if (!userDb.exists)
                userRef.set({
                  displayName: user.displayName,
                  email: user.email,
                });
              else {
                if (!userDb.data().displayName)
                  userRef.update({
                    displayName: user.displayName,
                    email: user.email,
                  });
              }

              if (userDb)
                setCurrentUser({
                  gender: "",
                  displayName: user.displayName,
                  ...userDb.data(),

                  id: user.uid,
                });
              r();
            });
        } else {
          setCurrentUser(user);
          r();
        }
      })
    );

    // const loadAppConfig = db
    //   .collection("apps")
    //   .doc("mf")
    //   .get()
    //   .then((querySnapshot) => {

    //     setAppConfig(querySnapshot.data());
    //   })
    //   .catch(console.log);

    Promise.all([loadUser])
      .then((s) => setPending(false))
      .catch((e) => console.log(e));
  }, [id]);

  if (pending) {
    return (
      <div className="loading-page">
        <img src="/assets/images/logo.svg" />
      </div>
    );
  }

  return (
    <div>
      <AuthContext.Provider
        value={{
          currentUser,
          appConfig,
          reload,
        }}
      >
        {children}
      </AuthContext.Provider>
    </div>
  );
};
