import React from "react";
import "./index.scss";

export default ({ configs = [], data = [] }) => {
  const renderField = (header, item) => {
    if (header.render) return header.render(item);
    return item[header.field];
  };

  return (
    <table className="custom-table w-100">
      <thead>
        <tr>
          {configs.map((item) => (
            <th>{item.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr>
            {configs.map((header) => (
              <td>{renderField(header, item)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
