import React from "react";

export default ({ step, changeStep }) => {
  return (
    <div>
      <div className="stepper">
        <div className="line"></div>
        <div
          className="d-flex align-items-center flex-column"
          onClick={() => changeStep()}
        >
          <div className="stepper-ball active">1</div>
          <div className="text text-center text-primary mt-1">
            Dados
            <br /> Pessoais
          </div>
        </div>
        <div className="flex-1" />
        <div className="d-flex align-items-center flex-column">
          <div className={`stepper-ball ${step == 2 ? "active" : ""}`}>2</div>
          <div
            className={`text text-center  ${
              step == 2 ? "text-primary" : "text-second-black"
            }   mt-1`}
          >
            Pagamento
            <br /> e Finalização
          </div>
        </div>
      </div>
    </div>
  );
};
