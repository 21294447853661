import React from "react";
import VideoModal from "../components/modal/VideoModal";
import { useModal } from "react-modal-hook";

export function useVideoModal(src, start) {
  const [showModal, hideModal] = useModal(
    () => (
      <VideoModal
        onHide={hideModal}
        src={src}
        callToAction={() => {
          hideModal();
          start();
        }}
      />
    ),
    [src]
  );

  return showModal;
}
