import React, { useState } from "react";
import HelpQuestionModal from "../components/modal/HelpQuestionModal";
import { useModal } from "react-modal-hook";

export function useHelpModal(type, onHide, title, videos) {
  console.log(title, videos);
  const [showModal, hideModal] = useModal(
    () => (
      <HelpQuestionModal
        type={type}
        title={title}
        videos={videos}
        onHide={() => {
          if (onHide) onHide();
          hideModal();
        }}
      />
    ),
    [type, title, videos]
  );

  return (n) => {
    showModal();
  };
}
