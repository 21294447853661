import React, { useState } from "react";
import { formatText } from "../../../../helper/formatText";

export default ({ text }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="">
      {!show ? (
        <div
          className="text-primary font-weight-bold"
          style={{ cursor: "pointer" }}
          onClick={() => setShow((v) => !v)}
        >
          {" "}
          Ver preview{" "}
        </div>
      ) : (
        <div
          className="text-danger font-weight-bold"
          style={{ cursor: "pointer" }}
          onClick={() => setShow((v) => !v)}
        >
          {" "}
          Esconder preview{" "}
        </div>
      )}
      {show && <div>{formatText(text)}</div>}
    </div>
  );
};
