import React, { useEffect, useState } from "react";
import FileUploader from "react-firebase-file-uploader";
import * as firebase from "firebase/app";
import PalletsColor from "./PalletsColor";

export default ({ value, onChange }) => {
  const [itens, setItens] = useState([]);

  useEffect(() => {
    if (value) setItens(value);
    console.log("Setting p", value);
  }, []);

  useEffect(() => {
    console.log("on change pallet");
    onChange(itens);
    // if (JSON.stringify(value) != JSON.stringify(itens)) onChange(itens);
    // console.log("change 2", JSON.stringify(value), JSON.stringify(itens));
  }, [itens]);

  const addItem = () => {
    setItens((itens) =>
      itens.concat([
        {
          type: "",
          colors: [],
        },
      ])
    );
  };

  const removeItem = (item) => {
    var result = window.confirm("Deseja remover item?");

    if (result) setItens((itens) => itens.filter((_item) => _item != item));
  };

  const register = (name, item) => {
    return {
      value: item[name],
      onChange: (e) => {
        const value = e && e.target && e.target.value ? e.target.value : e;
        setItens((itens) =>
          itens.map((_item) =>
            _item != item
              ? _item
              : {
                  ...item,
                  [name]: value,
                }
          )
        );
      },
    };
  };
  return (
    <div className="p-4">
      {/* <pre>{JSON.stringify(itens, null, 2)}</pre> */}
      {itens.map((item, index) => (
        <div
          className="form-group p-4 rounded position-relative"
          style={{ background: "rgba(0,0,0,.1)" }}
          key={index}
        >
          <label
            className="text-danger font-weight-bold cursor-pointer"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
              cursor: "pointer",
            }}
            onClick={() => removeItem(item)}
          >
            Excluir
          </label>
          <label>Item #{index + 1}</label>

          <div className="form-group">
            <label>Tipo</label>
            <input className="form-control" {...register("type", item)} />
          </div>
          <div className="form-group">
            <label>Cores</label>
            <PalletsColor {...register("colors", item)} />
          </div>
        </div>
      ))}

      <button className="btn btn-primary w-100" onClick={addItem}>
        Adicionar item
      </button>
    </div>
  );
};
