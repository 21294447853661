import React, { useState } from "react";
import Question from "../../components/question/Question";
import { Link, Redirect } from "react-router-dom";
import { useFinish } from "../../hooks/uesFinishQuestion";
import { formatText } from "../../helper/formatText";
import QuizFinish from "../../components/quiz/QuizFinish";

export default ({ match: { params } }) => {
  const { idQuiz } = params;

  const [meunoOption, setMenuOption] = useState(0);
  const menu = ["Estilo Primário", "Estilo Secundário"];
  const changeMenu = (value) => () => setMenuOption(value);
  const finish = useFinish(idQuiz);
  const isMultipleResults = Array.isArray(finish) && finish.length > 1;

  if (finish && finish.redirectToCheckout)
    return <Redirect to={"/checkout/" + idQuiz} />;

  if (finish && finish.processing)
    return (
      <div class="card mb-4 shadow-sm rounded nt">
        <div class={`card-body bg-white p-0 custom-menu text-center`}>
          <div className="font-3 text-left">
            Estamos analisando os seus <b>resultados</b> e em até <b>24h</b>{" "}
            você receberá a sua <b>Análise de Coloração Pessoal</b> completa!
          </div>
          <img className="mt-3" src="/assets/images/clock.svg" />
        </div>
      </div>
    );
  console.log("Stauts", finish);
  if (!finish || !finish[meunoOption]) return <p></p>;

  return (
    <div class="container py-3">
      <QuizFinish
        finish={finish[meunoOption]}
        menu={
          isMultipleResults ? (
            <div className="d-flex flex-row mb-3">
              {menu.map((item, index) => (
                <div
                  onClick={changeMenu(index)}
                  className={
                    "result-option " + (index == meunoOption ? " active" : "")
                  }
                  key={index}
                >
                  {item}
                </div>
              ))}
            </div>
          ) : (
            false
          )
        }
      />
    </div>
  );
};
