import React from "react";
import EditableValue from "../app-config/Value";

export default ({ buttonText, description }) => {
  return (
    <div class="card shadow-sm rounded bg-primary-light p-4">
      <div class={`card-body `}>
        <div className="mb-3 d-flex align-items-center justify-content-center">
          <img src="/assets/images/logo.svg" />
        </div>
        <EditableValue
          field="home-text-1"
          className="card-text text-center text-black p-0 font-3 mb-0 pt-3"
        />
        <div className="flex-1 mt-3 w-100 text-center">
          <img
            className="w-100 h-100"
            style={{
              borderRadius: "8px",
              maxWidth: 300,
            }}
            src="https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/img-principal.png?alt=media"
          />
        </div>
      </div>
    </div>
  );
};
