import React from "react";
import Value from "../app-config/Value";

export default ({ title, description, type = "primary", image }) => {
  return (
    <div class="card mb-4 shadow-sm rounded bg-complement w-100">
      <div
        class="background-image bd-placeholder-img card-img-top bg-bg-complement"
        style={{
          height: 225,
          backgroundImage: `url(${image})`,
        }}
      ></div>

      <div class={`card-body bg-${type}`}>
        <Value field={title} className="card-text h4" />
        <Value field={description} className="card-text" />
      </div>
    </div>
  );
};
