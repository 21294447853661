import { db } from "../base";
import { useState, useEffect } from "react";
import useCurrentUser from "./useCurrentUser";
import { useHistory } from "react-router-dom";

export function useQuestion(productId, questionId) {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const [question, setCurrentQuestion] = useState();
  const [nextQuestion, setNextQuestion] = useState();
  const [backQuestion, setBackQuestion] = useState();

  const getQuestion = async () => {
    const question = await db
      .collection("products")
      .doc(productId)
      .collection("questions")
      .doc(questionId)
      .get();

    const userProduct = (
      await db
        .collection("users")
        .doc(currentUser.id)
        .collection("products")
        .doc(productId)
        .get()
    ).data();

    const currentId = userProduct.questions.findIndex(
      (q) => q.id == questionId
    );

    if (userProduct.result) {
      history.push(`/quiz/${productId}/finish`);
      return;
    }

    const questionData = question.data();
    let options = questionData.options;
    //
    if (questionData.prerequisite) {
      const prerequisiteAnswer = userProduct.questions.find(
        (q) => q.id == questionData.prerequisite.questionId
      );
      const values = questionData.prerequisite[prerequisiteAnswer.value];
      console.log(
        values,
        options.map((o) => o.value)
      );
      options = options
        .filter((option) => values.includes(option.value))
        .sort((a, b) => values.indexOf(a.value) - values.indexOf(b.value));
      console.log(options.map((o) => o.value));
    }

    if (userProduct.questions.length > currentId + 1)
      setNextQuestion(userProduct.questions[currentId + 1].id);
    else setNextQuestion(null);

    setCurrentQuestion({
      ...questionData,
      options,
      value: userProduct.questions.find((q) => q.id == questionId).value,
    });

    if (currentId - 1 >= 0)
      setBackQuestion(userProduct.questions[currentId - 1].id);
    else setBackQuestion(null);
  };

  const answerQuestion = (value) => async () => {
    let valueToUpdate;
    console.log("--", value);

    const a = {
      twoOptions: () => {
        valueToUpdate = (Array.isArray(question.value)
          ? [...question.value]
          : [question.value]
        ).filter((item) => !!item);
        if (!value) valueToUpdate = value;
        console.log(value, question.value);
        if (
          question.value &&
          question.value.includes &&
          question.value.includes(value)
        ) {
          valueToUpdate = question.value.filter((f) => f != value);
        } else {
          if (valueToUpdate) valueToUpdate.push(value);
        }

        if (valueToUpdate && valueToUpdate.length > 2) throw new Error();
      },
      common: () => {
        valueToUpdate = value;
      },
      colorQuestion: () => {
        valueToUpdate = value;
      },
    };

    try {
      if (a[question.type]) {
        a[question.type]();
      } else {
        a["twoOptions"]();
      }
    } catch (error) {
      console.log(error);
      return;
    }

    setCurrentQuestion((current) => ({
      ...current,
      value: valueToUpdate,
    }));

    const product = await db
      .collection("users")
      .doc(currentUser.id)
      .collection("products")
      .doc(productId);

    const productData = (await product.get()).data();

    product.update({
      questions: productData.questions.map((item) =>
        item.id == questionId ? { id: item.id, value: valueToUpdate } : item
      ),
    });

    // if (goNext) {
    //   if (nextQuestion)
    //     history.push(`/quiz/${productId}/question/${nextQuestion}`);
    //   else history.push(`/quiz/${productId}/finish`);
    // }
  };

  const goBack = () => {
    if (backQuestion)
      history.push(`/quiz/${productId}/question/${backQuestion}`);
  };

  const goNext = () => {
    if (nextQuestion)
      history.push(`/quiz/${productId}/question/${nextQuestion}`);
  };

  console.log(nextQuestion);

  useEffect(() => {
    getQuestion();
  }, [productId, questionId]);

  return {
    question,
    answerQuestion,
    goBack,
    goNext,
    hasBack: !!backQuestion,
    hasNext: !!nextQuestion,
  };
}
