import React, { useRef, useEffect, useState, Component } from "react";

import * as facemesh from "@tensorflow-models/facemesh";
import * as tf from "@tensorflow/tfjs-core";

// import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
// import "@tensorflow/tfjs-backend-webgl";

export default class Teste extends Component {
  componentDidMount() {
    this.setupPage();
  }

  points = [];
  state = {
    loading: true,
  };
  componentWillUnmount() {
    if (this.stopVideo) this.stopVideo();
  }

  setupCamera = async function () {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: "user",
      },
    });
    this.video.srcObject = stream;
    this.stopVideo = () => stream.getTracks()[0].stop();
    return new Promise((resolve) => {
      this.video.onloadedmetadata = () => {
        resolve(this.video);
      };
    });
  };

  renderCanvas = async () => {
    // await this.userImage();
    await this.renderPrediction1();
    window.requestAnimationFrame(() => this.renderCanvas());
  };

  userImage = () => {
    if (!this.canvas) return;
    this.ctx2.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx2.drawImage(
      this.video,
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );
  };

  renderPrediction1 = async (...args) => {
    if (!this.canvas) return;
    if (!this.model || !this.ctx) return;
    const ctx = this.ctx;

    let center = this.center || [this.canvas.width / 2, this.canvas.height / 2];

    const drwaTriagle = (color, start, end) => {
      ctx.beginPath();
      ctx.moveTo(...start);
      ctx.lineTo(...center);
      ctx.lineTo(...end);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    };
    const { c1, c2, c3, c4 } = this.props;
    const diffSize =
      ((this.canvas.offsetWidth - window.innerWidth) *
        (this.canvas.width / this.canvas.offsetWidth)) /
      2;
    let initialWidth = Math.max(0, diffSize);
    const endWidth = this.canvas.width - initialWidth;

    const predictions = await this.model.estimateFaces(this.video);
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    drwaTriagle(c1, [endWidth, 0], [endWidth, this.canvas.height]);
    drwaTriagle(c2, [initialWidth, 0], [endWidth, 0]);
    drwaTriagle(c3, [initialWidth, 0], [initialWidth, this.canvas.height]);
    drwaTriagle(
      c4,
      [initialWidth, this.canvas.height],
      [endWidth, this.canvas.height]
    );

    if (predictions.length > 0) {
      const prediction = predictions[0];
      this.setState({
        loading: false,
      });
      const { silhouette, noseTip } = prediction.annotations;
      this.center = [noseTip[0][0], noseTip[0][1]];
      ctx.globalCompositeOperation = "destination-out";

      ctx.beginPath();

      if (this.points.length == 0) {
        this.points = silhouette.map((i, index) => ({
          index,
          values: [i],
        }));
      } else {
        this.points = this.points.map((item) => ({
          index: item.index,
          values:
            item.values.length == 5
              ? [...item.values.slice(1), silhouette[item.index]]
              : item.values.concat([silhouette[item.index]]),
        }));
      }
      const initialLength = this.points[0].values.length;
      const [initialX, initialY] = this.points[0].values.reduce(
        (a, b) => [a[0] + b[0], a[1] + b[1]],
        [0, 0]
      );
      ctx.moveTo(initialX / initialLength, initialY / initialLength);
      for (let i = 0; i < this.points.length; i++) {
        const length = this.points[i].values.length;
        const [x, y] = this.points[i].values.reduce(
          (a, b) => [a[0] + b[0], a[1] + b[1]],
          [0, 0]
        );
        ctx.lineTo(x / length, y / length);
      }
      ctx.closePath();
      ctx.fill();
      ctx.globalCompositeOperation = "source-over";
    } else {
      this.center = undefined;
    }
  };
  setupPage = async () => {
    // // await tfjsWasm.setWasmPath(
    // //   "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@latest/dist/tfjs-backend-wasm.wasm"
    // // );

    // await tfjsWasm.setWasmPath(
    //   `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/tfjs-backend-wasm.wasm`
    // );
    await tf.setBackend("wasm");
    await this.setupCamera();
    // this.video.play();

    let videoWidth = this.video.videoWidth;
    let videoHeight = this.video.videoHeight;
    // this.video.width = videoWidth;
    // this.video.height = videoHeight;

    this.canvas.width = videoWidth;
    this.canvas.height = videoHeight;
    // this.canvas2.width = videoWidth;
    // this.canvas2.height = videoHeight;
    let ctx = this.canvas.getContext("2d");
    // let ctx2 = this.canvas2.getContext("2d");
    ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
    this.ctx = ctx;
    // this.ctx2 = ctx2;

    // let model = await blazeface.load();

    console.log("One");
    try {
      console.log("One,");
      this.model = await facemesh.load({ maxFaces: 1 });
      // .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      throw e;
    }
    console.log("Two");
    window.requestAnimationFrame(() => this.renderCanvas());
  };

  render() {
    return (
      <div className="position-relative h-100 align-items-center justify-content-center d-flex">
        {/* <div
          className="h-100 d-block"
          style={{ width: window.innerWidth }}
        ></div> */}
        {this.state.loading && (
          <div className="loading-page-face">
            <div class="loading"></div>
          </div>
        )}
        <video
          ref={(ref) => (this.video = ref)}
          // muted
          autoPlay
          playsInline
          style={{
            // display: "none",
            // visibility: "hidden",
            transform: "scaleX(-1)",
            height: "100%",
            // opacity: 0.5,
          }}
        />
        {/* <canvas
          className="position-absolute h-100 "
          ref={(ref) => (this.canvas2 = ref)}
          style={{ top: 0, transform: "scaleX(-1)", opacity: 0.5 }}
        /> */}
        <canvas
          className="position-absolute h-100 "
          ref={(ref) => (this.canvas = ref)}
          style={{ top: 0, transform: "scaleX(-1)" }}
        />
      </div>
    );
  }
}
