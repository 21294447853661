import React, { useEffect, useState } from "react";
import { db } from "../../base";

async function getSubset(productId, set) {
  let result = [];
  return new Promise((r) => {
    db.collection("products")
      .doc(productId)
      .collection(set)
      .onSnapshot((data) => {
        r(
          data.docs.map((d) => ({
            ...d.data(),
            id: d.id,
          }))
        );
      });
  });
}
export function useBackup() {
  const [products, setProducts] = useState();

  useEffect(() => {
    db.collection("products").onSnapshot((data) => {
      setProducts(
        data.docs.map((d) => ({
          ...d.data(),
          id: d.id,
        }))
      );
    });
  }, []);

  useEffect(() => {
    if (products)
      (async () => {
        const newProducts = await products.map(async (product) => ({
          ...product,
          results: await getSubset(product.id, "results"),
          questions: await getSubset(product.id, "questions"),
        }));

        setProducts(await Promise.all(newProducts));
      })();
  }, [products]);

  return products;
}

export default () => {
  const backup = useBackup();
  return <pre>{JSON.stringify(backup, null, 2)}</pre>;
};
