import React from "react";
import Question from "../../components/question/Question";
import { Link, Redirect } from "react-router-dom";

export default ({ match: { params } }) => {
  return (
    <div class="container py-3">
      <h3 className="text-primary mb-4 font-weight-normal text-center">
        Editar produto
      </h3>
    </div>
  );
};
