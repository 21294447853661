import React from "react";
import { useStartProduct } from "../../hooks/useProducts";
import { formatText } from "../../helper/formatText";
import { useVideoModal } from "../../hooks/useVideoModal";
import { Link } from "react-router-dom";

export default ({
  title,
  description,
  price,
  type = "primary",
  buttonText,
  id,
  image,
  about,
  done,
  needPay,
  resultName,
}) => {
  const start = useStartProduct(id);

  const openVideoModal = useVideoModal(about && about.video, start);

  return (
    <div class="card mb-4 shadow-sm rounded card-price  w-100">
      <div class={`card-body d-flex flex-column`}>
        {done ? (
          <div className="mb-2 position-relative">
            <p class="card-text title w-75">{formatText(resultName)}</p>
            <div className={`ribbon bg-success`}>Concluído</div>
          </div>
        ) : (
          <>
            <div className="mb-2 position-relative">
              <p class="card-text title w-75">{formatText(title)}</p>
              <div className={`ribbon bg-${type}`}>{price}</div>
            </div>
            <p class="card-text text-muted flex-1">{formatText(description)}</p>
          </>
        )}

        <p class="card-text text-muted flex-1"></p>
        <div
          class="background-image bd-placeholder-img card-img-top bg-bg-complement rounded mb-4"
          style={{
            height: 120,
            backgroundImage: `url(${image})`,
          }}
        ></div>
        <div className="d-flex w-100">
          {about && (
            <>
              <button
                className="btn btn-third btn-lg flex-1 rounded"
                onClick={openVideoModal}
              >
                Saiba Mais
              </button>
              <div className="p-1"></div>
            </>
          )}
          {needPay ? (
            <Link
              className="btn btn-primary btn-lg flex-1 rounded"
              to={`quiz/${id}/finish`}
            >
              Finalizar
            </Link>
          ) : (
            <button
              className="btn btn-primary btn-lg flex-1 rounded"
              onClick={start}
            >
              {done ? "Ver resultado" : buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
