import React from "react";
import { formatText } from "../../helper/formatText";
import { Link } from "react-router-dom";

export default ({ question, answerQuestion, goBack, goNext, productId }) => {
  const options1 = (question.options || []).filter(
    (i, index) => index % 2 == 0
  );
  const options2 = (question.options || []).filter(
    (i, index) => index % 2 !== 0
  );
  console.log(options2, options1);
  return (
    <div>
      <h3 className="text-primary mb-4 font-weight-normal text-center">
        {question.title}
      </h3>
      <div class="card mb-4 shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white gallery`}>
          <p class="card-text mb-2 text-black font-2 mb-0 ">
            {formatText(question.question)}
          </p>

          <div className="row justify-content-center">
            {(question.options || []).map((option, index) => (
              <div
                className="col-6 col-md-3 col-sm-6 col-xs-6  p-1 pl-2 mb-2"
                key={option.image}
              >
                <div
                  className="img-option "
                  onClick={answerQuestion(option.value)}
                >
                  {(question.value &&
                  question.value.includes &&
                  question.value.includes(option.value)
                    ? " active"
                    : "") && (
                    <div className="overlay">
                      <div className="number">
                        {question.value.indexOf(option.value) + 1}
                      </div>
                    </div>
                  )}

                  <img src={option.image} />
                </div>

                {option.text && (
                  <div className="text-second-black">{option.text}</div>
                )}
              </div>
            ))}
          </div>
          {/* <ul>
            {(question.options || []).map((option, index) => (
              <li onClick={answerQuestion(option.value)}>
                <div className="img-option">
                  {(question.value == option.value ? " active" : "") && (
                    <div className="overlay"> </div>
                  )}
                </div>
              </li>
            ))}
          </ul> */}

          <div className="">
            {goBack && (
              <button
                type="button"
                className="btn btn-sm back-button rounded mt-4 px-3 d-inline"
                onClick={goBack}
              >
                Anterior
              </button>
            )}
            {goNext ? (
              <button
                type="button"
                disabled={!question.value || question.value.length !== 2}
                className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right d-inline"
                onClick={goNext}
              >
                Continuar
              </button>
            ) : !question.value || question.value.length !== 2 ? (
              <button
                type="button"
                disabled={!question.value}
                className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right d-inline"
              >
                Finalizar
              </button>
            ) : (
              <Link
                // disabled={!question.value}
                // style={{ opacity: !question}}

                to={`/quiz/${productId}/finish`}
              >
                <button className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right">
                  Finalizar
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
