import React, { useContext } from "react";
import { ColorQuestionContext } from "./ColorQuestionContext";

export default () => {
  const { showInfoModal, goBack } = useContext(ColorQuestionContext);
  const hasBack = false;
  return (
    <div>
      {goBack && (
        <div
          className=" position-absolute "
          onClick={goBack}
          style={{
            left: 0,
            top: 0,
            paddingTop: 24,
            paddingLeft: 24,
            zIndex: 1010,
          }}
        >
          <div className="back-button-color-question d-flex align-items-center font-1">
            <img
              src="/assets/images/back-2.svg"
              height="12px"
              className="mr-1"
            />
            Anterior
          </div>
        </div>
      )}

      <div
        className=" position-absolute "
        onClick={showInfoModal}
        style={{
          right: 0,
          top: 0,
          paddingTop: 24,
          paddingRight: 24,
          zIndex: 1010,
        }}
      >
        <img src="/assets/images/help.svg" width="36px" />
      </div>
    </div>
  );
};
