import React from "react";

export default ({
  videos = [
    // "/assets/video-1.mp4",
    // "/assets/video-1.mp4",
    // "/assets/video-1.mp4",
  ],
}) => {
  return (
    <div className="d-flex pt-4 pb-1" style={{ overflow: "auto" }}>
      {videos.map((video, index) =>
        !video ? null : (
          <div
            key={index}
            className="position-relative p-2 "
            style={{ minWidth: videos.length == 1 ? undefined : 200 }}
          >
            {videos.length > 1 && (
              <div
                className={`video-title ${
                  videos.length == 1 ? "w-100" : "w-100"
                }`}
              >
                {video.title}
              </div>
            )}
            <video
              src={video.src}
              style={{
                borderRadius: 10,
                width: "100%",
                height: videos.length == 1 ? undefined : "100%",
                background: "black",
              }}
              autoPlay={videos.length == 1}
              controls
            />
          </div>
        )
      )}
    </div>
  );
};
