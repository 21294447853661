import React, { useEffect, useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import Story from "./Story";
export default ({ values = [] }) => {
  const [options, setOptions] = useState([]);
  const [tab, currentTab] = useState(0);

  const { gender } = useCurrentUser() || {};
  const changeTab = (value) => () => currentTab(value);

  useEffect(() => {
    const optionsObj = values
      .map((v) => v.type)
      .reduce(
        (a, b) => ({
          ...a,
          [b]: a[b] ? a[b] + 1 : 1,
        }),
        {}
      );
    const labels = Object.keys(optionsObj).sort(
      (a, b) => optionsObj[b] - optionsObj[a]
    );
    console.log(labels);
    if (gender && labels.find((l) => l.toLowerCase() == gender.toLowerCase())) {
      setOptions([labels.find((l) => l.toLowerCase() == gender.toLowerCase())]);
    } else {
      setOptions(labels || []);
    }
  }, []);
  return (
    <div className="d-flex flex-column">
      <div className="story-title">Inspirações de look do seu estilo:</div>
      {options.length > 1 && (
        <div class="card-text  font-2 result-text mb-2 d-flex flex-row justify-content-center">
          {options.map((op, index) => (
            <div
              className={`gender-select mr-2 ${index == tab ? "active" : ""}`}
              onClick={changeTab(index)}
              key={op}
            >
              {op}
            </div>
          ))}
        </div>
      )}

      <Story itens={values.filter((f) => f.type == options[tab])} />
    </div>
  );
};
