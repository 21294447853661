import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FaceMeshFaceGeometry } from "./libs/face";

// Physics variables
var clothWidth = 200;
var clothHeight = 200;
var gravityConstant = -9.8 * 10;
var physicsWorld;
var rigidBodies = [];
var margin = 0.05;
var hinge;
var cloth;
var arm;
var height;
var width;
var transformAux1;
var clock = new THREE.Clock();
var angle = 0;

var armMovement = 0;
var Ammo_;
var square;

function createSlice(maskMaterial) {
  const size = 20;
  const depth = -50;
  const plane_ = new THREE.Geometry();

  const vectors = [
    [0, 250.0 / 2, 35],
    [0, 0, 0],
    [-28.8726806640625, -0.1134185791015625, 1.5745124816894531],
    [-53.27374267578125, 3.3575439453125, 7.9350385665893555],
    [-75.46981811523438, 11.441726684570312, 19.058069229125977],
    [-89.61636352539062, 24.230758666992188, 32.23100280761719],
    [-98.27981567382812, 40.69207763671875, 45.235260009765625],
    [-102.839111328125, 58.498046875, 59.204755783081055],
    [-104.92462158203125, 81.14639282226562, 70.76048469543457],
    [-104.602783203125, 102.67196655273438, 76.7614574432373],
    [-104.2664794921875, 124.52166748046875, 78.88257026672363],
    [-102.25125122070312, 148.16033935546875, 77.94746971130371],
    [-96.9693603515625, 172.96194458007812, 73.88456916809082],
    [-88.91683959960938, 193.38177490234375, 65.22085762023926],
    [-79.30133056640625, 209.115234375, 55.1533260345459],
    [-66.56234741210938, 223.4560546875, 46.766605377197266],
    [-55.300689697265625, 233.63058471679688, 40.244924545288086],
    [-43.128692626953125, 242.63388061523438, 33.54092216491699],
    [-28.756439208984375, 249.04083251953125, 29.253774642944336],
    [-9.920501708984375, 251.552001953125, 27.644256591796875],
    [9.048736572265625, 250.25796508789062, 29.371867179870605],
    [23.77386474609375, 244.75991821289062, 33.696556091308594],
    [36.447235107421875, 236.61477661132812, 40.509653091430664],
    [48.289093017578125, 227.1341552734375, 47.02635383605957],
    [61.889678955078125, 213.67623901367188, 55.454877853393555],
    [72.52436828613281, 198.57666015625, 65.66283226013184],
    [81.95838928222656, 178.79629516601562, 74.36033058166504],
    [89.0072021484375, 154.37164306640625, 78.43661689758301],
    [92.79470825195312, 131.01348876953125, 79.39130592346191],
    [94.73291015625, 109.31658935546875, 77.2609920501709],
    [96.76179504394531, 87.81936645507812, 71.26676368713379],
    [96.62303161621094, 65.24920654296875, 59.695207595825195],
    [93.58004760742188, 47.22540283203125, 45.680946350097656],
    [86.58039855957031, 30.27069091796875, 32.69436836242676],
    [73.73103332519531, 16.498077392578125, 19.43251872062683],
    [52.47601318359375, 6.9250640869140625, 8.264370918273926],
    [28.653594970703125, 1.82965087890625, 1.7291803359985352],
  ];
  localStorage.mask = JSON.stringify(vectors);

  vectors.forEach((i) => plane_.vertices.push(new THREE.Vector3(...i)));

  for (let i = 1; i < vectors.length; i++) {
    let next = i + 1;
    if (next == vectors.length) next = 1;
    plane_.faces.push(new THREE.Face3(0, i, next));
  }
  // plane_.faces.push(new THREE.Face3(1, 0, 4));
  // plane_.faces.push(new THREE.Face3(1, 2, 5));
  // plane_.faces.push(new THREE.Face3(1, 4, 5));
  // plane_.faces.push(new THREE.Face3(0, 1, 2));
  // plane_.faces.push(new THREE.Face3(0, 2, 3));
  // plane_.faces.push(new THREE.Face3(0, 3, 4));
  // plane_.faces.push(new THREE.Face3(2, 3, 5));
  // plane_.faces.push(new THREE.Face3(3, 4, 5));
  // this.plane_.faces.push(new THREE.Face3(2, 5, 0));

  plane_.faces.forEach((face, ndx) => {
    face.vertexColors = [
      new THREE.Color().setHSL(ndx / 12, 1, 0.5),
      new THREE.Color().setHSL(ndx / 12 + 0.1, 1, 0.5),
      new THREE.Color().setHSL(ndx / 12 + 0.2, 1, 0.5),
    ];
  });
  // this.plane_.faces.push(new THREE.Face3(0, 1, 2));
  // this.plane_.faces.push(new THREE.Face3(0, 1, 2));
  // this.plane_.faces.push(new THREE.Face3(0, 1, 2));

  const material = new THREE.MeshBasicMaterial({
    vertexColors: THREE.VertexColors,
    side: THREE.DoubleSide,
  });

  plane_.computeFaceNormals();

  const planeGeo_ = new THREE.Mesh(plane_, maskMaterial);
  planeGeo_.rotateX(Math.PI / 2);
  planeGeo_.scale.setScalar(0.25);
  planeGeo_.position.set(0, 35, -125 * 0.25);
  return planeGeo_;
}

export default class Scene {
  constructor(prediction, video) {
    this.prediction = prediction;
    if (window.Ammo)
      window.Ammo().then((AmmoLib) => {
        Ammo_ = AmmoLib;
        this.init();
        this.animate();
      });
  }

  resize() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    this.renderer.setSize(windowWidth, windowHeight);
    this.camera.aspect = windowWidth / windowHeight;
    this.camera.updateProjectionMatrix();
  }

  init() {
    this.initGraphics();
    this.initPhysics();
    this.createObjects();
    this.initInput();
  }

  initGraphics() {
    this.container = document.getElementById("container");

    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.2,
      2000
    );

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xbfd1e5);

    this.camera.position.set(0, clothHeight, 0);

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight - 88);
    this.renderer.shadowMap.enabled = true;
    this.container.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(0, 2, 0);
    this.controls.update();

    this.textureLoader = new THREE.TextureLoader();

    var ambientLight = new THREE.AmbientLight(0x404040);
    this.scene.add(ambientLight);

    var light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(-7, 10, 15);
    light.castShadow = true;
    var d = 10;
    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;

    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 50;

    light.shadow.mapSize.x = 1024 * 2;
    light.shadow.mapSize.y = 1024 * 2;

    light.shadow.bias = 3;
    this.scene.add(light);

    window.addEventListener("resize", this.onWindowResize, false);
    this.renderer.setPixelRatio(window.devicePixelRatio);
  }

  initPhysics() {
    var collisionConfiguration = new Ammo_.btSoftBodyRigidBodyCollisionConfiguration();
    var dispatcher = new Ammo_.btCollisionDispatcher(collisionConfiguration);
    var broadphase = new Ammo_.btDbvtBroadphase();
    var solver = new Ammo_.btSequentialImpulseConstraintSolver();
    var softBodySolver = new Ammo_.btDefaultSoftBodySolver();
    this.physicsWorld = new Ammo_.btSoftRigidDynamicsWorld(
      dispatcher,
      broadphase,
      solver,
      collisionConfiguration,
      softBodySolver
    );
    this.physicsWorld.setGravity(new Ammo_.btVector3(0, gravityConstant, 0));
    this.physicsWorld
      .getWorldInfo()
      .set_m_gravity(new Ammo_.btVector3(0, gravityConstant, 0));

    transformAux1 = new Ammo_.btTransform();
  }

  createObjects() {
    var pos = new THREE.Vector3();
    var quat = new THREE.Quaternion();

    // Ground
    pos.set(0, 0, 0);
    quat.set(0, 0, 0, 1);
    // var ground = this.createParalellepiped(
    //   40,
    //   0,
    //   40,
    //   0,
    //   pos,
    //   quat,
    //   new THREE.MeshPhongMaterial({ color: 0xffffff })
    // );
    // ground.castShadow = true;
    // ground.receiveShadow = true;
    // this.textureLoader.load("/assets/textures/grid.png", function (texture) {
    //   texture.wrapS = THREE.RepeatWrapping;
    //   texture.wrapT = THREE.RepeatWrapping;
    //   texture.repeat.set(40, 40);
    //   ground.material.map = texture;
    //   ground.material.needsUpdate = true;
    // });
    const video = document.getElementById("video-id");
    const w = video.videoWidth;
    const h = video.videoHeight;

    video.play();
    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.encoding = THREE.sRGBEncoding;
    this.maskMaterial = new THREE.MeshStandardMaterial({
      // color: 0xff0000,
      // roughness: 0.7,
      // metalness: 0.0,
      map: videoTexture, // Will be created when the video is ready.
      side: THREE.DoubleSide,
    });
    // The cloth
    // Cloth graphic object
    var clothNumSegmentsZ = 30;
    var clothNumSegmentsY = 30;
    var clothPos = new THREE.Vector3(-clothWidth / 2, 3, clothHeight / 2);
    // this.scene.add(createSlice(this.maskMaterial));
    var clothGeometry = new THREE.PlaneBufferGeometry(
      clothWidth,
      clothHeight,
      clothNumSegmentsZ,
      clothNumSegmentsY
    );
    clothGeometry.rotateY(Math.PI * 0.5);
    clothGeometry.translate(
      clothPos.x,
      clothPos.y + clothHeight * 0.5,
      clothPos.z - clothWidth * 0.5
    );

    var clothMaterial = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
    });

    clothGeometry.computeFaceNormals();
    clothGeometry.computeVertexNormals();
    clothGeometry.computeBoundingBox();

    cloth = new THREE.Mesh(clothGeometry, clothMaterial);
    cloth.castShadow = true;
    cloth.receiveShadow = true;
    this.scene.add(cloth);
    this.textureLoader.load("/assets/textures/mesh1.png", function (texture) {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1, 1);
      cloth.material.map = texture;
      cloth.material.needsUpdate = true;
    });

    // Cloth physic object
    var softBodyHelpers = new Ammo_.btSoftBodyHelpers();
    // const heightCloth = 2;
    var heightCloth = 10;
    var pylonHeight = 10;
    var clothCorner00 = new Ammo_.btVector3(
      clothPos.x,
      heightCloth,
      clothPos.z
    );
    var clothCorner01 = new Ammo_.btVector3(
      clothPos.x,
      heightCloth,
      clothPos.z - clothWidth
    );
    var clothCorner10 = new Ammo_.btVector3(
      clothPos.x + clothHeight,
      heightCloth,
      clothPos.z
    );
    var clothCorner11 = new Ammo_.btVector3(
      clothPos.x + clothHeight,
      heightCloth,
      clothPos.z - clothWidth
    );
    var clothSoftBody = softBodyHelpers.CreatePatch(
      this.physicsWorld.getWorldInfo(),
      clothCorner00,
      clothCorner01,
      clothCorner10,
      clothCorner11,
      clothNumSegmentsZ + 1,
      clothNumSegmentsY + 1,
      0,
      true
    );
    var sbConfig = clothSoftBody.get_m_cfg();
    sbConfig.set_viterations(30);
    sbConfig.set_piterations(30);

    clothSoftBody.setTotalMass(10, false);
    Ammo_.castObject(clothSoftBody, Ammo_.btCollisionObject)
      .getCollisionShape()
      .setMargin(margin * 3);
    this.physicsWorld.addSoftBody(clothSoftBody, 1, -1);
    cloth.userData.physicsBody = clothSoftBody;
    // Disable deactivation
    clothSoftBody.setActivationState(4);

    // The base
    var armMass = 2;
    var armLength = +clothWidth;
    var baseMaterial = new THREE.MeshPhongMaterial({ color: 0x606060 });
    var baseMaterial2 = new THREE.MeshPhongMaterial({ color: 0xff0000 });
    quat.set(0, 0, 0, 1);
    pos.set(clothPos.x, 0.5 * pylonHeight, clothPos.z - armLength);

    pos.set(clothPos.x, pylonHeight + 0.2, clothPos.z - 0.5 * armLength);
    arm = this.createParalellepiped(
      clothHeight / (5 * 10),
      clothHeight / (5 * 10),
      armLength + clothHeight / (5 * 10),
      armMass * 0,
      pos,
      quat,
      baseMaterial2
    );
    arm.castShadow = true;
    arm.receiveShadow = true;
    pos.set(
      clothPos.x + clothHeight,
      pylonHeight + 0.2,
      clothPos.z - 0.5 * armLength
    );

    var arm2 = this.createParalellepiped(
      clothHeight / (5 * 10),
      clothHeight / (5 * 10),
      armLength + clothHeight / (5 * 10),
      armMass * 0,
      pos,
      quat,
      baseMaterial2
    );
    arm2.castShadow = true;
    arm2.receiveShadow = true;

    // square

    pos.set(0, -2, 0);
    square = this.createParalellepiped(
      clothWidth / 5,
      clothWidth / 5,
      clothWidth / 5,
      0,
      pos,
      quat,
      this.maskMaterial,
      false
    );
    square.castShadow = true;
    square.receiveShadow = true;
    square.userData.physicsBody.setCollisionFlags(2);

    // Glue the cloth to the arm
    var influence = 0.5;

    clothSoftBody.appendAnchor(0, arm.userData.physicsBody, false, influence);
    clothSoftBody.appendAnchor(
      clothNumSegmentsZ,
      arm.userData.physicsBody,
      false,
      influence
    );
    clothSoftBody.appendAnchor(
      clothNumSegmentsZ * (clothNumSegmentsZ + 1),
      arm2.userData.physicsBody,
      false,
      influence
    );
    clothSoftBody.appendAnchor(
      clothNumSegmentsZ * (clothNumSegmentsZ + 1) + clothNumSegmentsZ,
      arm2.userData.physicsBody,
      false,
      influence
    );

    // const faceGeometry = new FaceMeshFaceGeometry({
    //   useVideoTexture: true,
    //   // normalizeCoords: true,
    // });
    // this.faceGeometry = faceGeometry;

    // this.mask = faceGeometry;

    // const mask = new THREE.Mesh(faceGeometry, this.maskMaterial);
    // mask.position.set(0, 20, 0);
    // // mask.scale.setScalar(1);
    // // mask.rotation.set(new THREE.Vector3(0, 0, Math.PI / 2));
    // mask.castShadow = true;
    // mask.receiveShadow = true;
    // faceGeometry.attributes.position.needsUpdate = true;
    // faceGeometry.computeVertexNormals();
    // mask.position.set(0, 0, 0);
    // this.scene.add(mask);
    // clothSoftBody.appendAnchor(
    //   (clothNumSegmentsZ * (clothNumSegmentsZ + 1) + clothNumSegmentsZ) / 2,
    //   square.userData.physicsBody,
    //   true,
    //   influence
    // );

    // // Hinge constraint to move the arm
    // var pivotA = new Ammo_.btVector3(0, pylonHeight * 0.5, 0);
    // var pivotB = new Ammo_.btVector3(0, -0.2, -armLength * 0.5);
    // var axis = new Ammo_.btVector3(0, 1, 0);
    // hinge = new Ammo_.btHingeConstraint(
    //   arm2.userData.physicsBody,
    //   arm2.userData.physicsBody,
    //   pivotA,
    //   pivotB,
    //   axis,
    //   axis,
    //   true
    // );
    // this.physicsWorld.addConstraint(hinge, true);
  }

  createParalellepiped(
    sx,
    sy,
    sz,
    mass,
    pos,
    quat,
    material,
    addOnScreen = true
  ) {
    var threeObject = new THREE.Mesh(
      new THREE.BoxBufferGeometry(sx, sy, sz, 1, 1, 1),
      material
    );
    var shape = new Ammo_.btBoxShape(
      new Ammo_.btVector3(sx * 0.5, sy * 0.5, sz * 0.5)
    );
    shape.setMargin(margin);

    this.createRigidBody(threeObject, shape, mass, pos, quat, addOnScreen);

    return threeObject;
  }

  createRigidBody(threeObject, physicsShape, mass, pos, quat, addOnScreen) {
    threeObject.position.copy(pos);
    threeObject.quaternion.copy(quat);

    var transform = new Ammo_.btTransform();
    transform.setIdentity();
    transform.setOrigin(new Ammo_.btVector3(pos.x, pos.y, pos.z));
    transform.setRotation(
      new Ammo_.btQuaternion(quat.x, quat.y, quat.z, quat.w)
    );
    var motionState = new Ammo_.btDefaultMotionState(transform);

    var localInertia = new Ammo_.btVector3(0, 0, 0);
    physicsShape.calculateLocalInertia(mass, localInertia);

    var rbInfo = new Ammo_.btRigidBodyConstructionInfo(
      mass,
      motionState,
      physicsShape,
      localInertia
    );
    var body = new Ammo_.btRigidBody(rbInfo);

    threeObject.userData.physicsBody = body;
    if (addOnScreen) this.scene.add(threeObject);

    // if (mass > 0) {
    rigidBodies.push(threeObject);

    // Disable deactivation
    body.setActivationState(4);
    // console.log(body);
    // }

    this.physicsWorld.addRigidBody(body);
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));

    this.render();
  }

  render() {
    var deltaTime = clock.getDelta();
    // console.log(this.renderer.info.render.triangles);

    const video = document.getElementById("video-id");
    // if (width !== video.videoWidth || height !== video.videoHeight) {
    //   const w = video.videoWidth;
    //   const h = video.videoHeight;
    //   width = w;
    //   height = h;
    //   this.resize();
    //   this.faceGeometry.setSize(w, h);
    // }

    this.updatePhysics(deltaTime);

    this.renderer.render(this.scene, this.camera);
  }

  async updatePhysics(deltaTime) {
    // Hinge control
    // hinge.enableAngularMotor(true, 0.8 * armMovement, 50);

    // armMovement
    // this.square.
    // Step world

    const faces = await this.prediction();
    // if (faces.length > 0) {
    //   // this.nose = faces[0].annotations.noseTip;
    //   this.mask.update(faces[0], true);
    // }

    this.physicsWorld.stepSimulation(deltaTime, 10);

    // Update cloth
    var softBody = cloth.userData.physicsBody;
    var clothPositions = cloth.geometry.attributes.position.array;
    var numVerts = clothPositions.length / 3;
    var nodes = softBody.get_m_nodes();
    var indexFloat = 0;
    for (var i = 0; i < numVerts; i++) {
      var node = nodes.at(i);
      var nodePos = node.get_m_x();
      clothPositions[indexFloat++] = nodePos.x();
      clothPositions[indexFloat++] = nodePos.y();
      clothPositions[indexFloat++] = nodePos.z();
    }
    cloth.geometry.computeVertexNormals();
    cloth.geometry.attributes.position.needsUpdate = true;
    cloth.geometry.attributes.normal.needsUpdate = true;

    // Update rigid bodies
    for (var i = 0, il = rigidBodies.length; i < il; i++) {
      var objThree = rigidBodies[i];
      var objPhys = objThree.userData.physicsBody;
      var ms = objPhys.getMotionState();
      if (ms) {
        ms.getWorldTransform(transformAux1);
        var p = transformAux1.getOrigin();
        var q = transformAux1.getRotation();
        objThree.position.set(p.x(), p.y(), p.z());
        objThree.quaternion.set(q.x(), q.y(), q.z(), q.w());
      }
    }
  }

  initInput() {
    // resultantImpulse.op_mul(scalingFactor);
    function degrees_to_radians(degrees) {
      var pi = Math.PI;
      return degrees * (pi / 180);
    }

    const rotate = () => {
      var transform = new Ammo_.btTransform();
      transform.setIdentity();
      let physicsBody = square.userData.physicsBody;
      transform.setRotation(
        new Ammo_.btQuaternion(0, 0, degrees_to_radians(angle), 1)
      );
      const ms = physicsBody.getMotionState(); //.getOrigin();]
      ms.getWorldTransform(transformAux1);
      var p = transformAux1.getOrigin();
      transform.setOrigin(new Ammo_.btVector3(p.x(), p.y(), p.z(), 0));
      physicsBody.getMotionState().setWorldTransform(transform);
    };
    window.addEventListener(
      "keydown",
      function (event) {
        switch (event.keyCode) {
          // Q
          case 81:
            angle += 1;
            rotate();
            break;

          // A
          case 65:
            angle -= 1;
            rotate();
            break;
        }
      },
      false
    );

    window.addEventListener(
      "keyup",
      function () {
        // armMovement = 0;
      },
      false
    );
  }
  onWindowResize() {}
}
