import React, { useEffect, useState } from "react";
import FileUploader from "react-firebase-file-uploader";
import * as firebase from "firebase/app";

export default ({ value, onChange }) => {
  const [itens, setItens] = useState([]);

  useEffect(() => {
    if (value) setItens(value);
  }, []);

  useEffect(() => {
    onChange(itens);
    // if (JSON.stringify(value) != JSON.stringify(itens)) onChange(itens);
    // console.log("change 4", itens);
  }, [itens]);

  const addItem = () => {
    setItens((itens) =>
      itens.concat([
        {
          mainColor: "",
          url: "",
          title: "",
        },
      ])
    );
  };

  const removeItem = (item) => {
    var result = window.confirm("Deseja remover item?");

    if (result) setItens((itens) => itens.filter((_item) => _item != item));
  };

  const register = (name, item) => {
    return {
      value: item[name],
      onChange: (e) => {
        const value = e.target.value;
        setItens((itens) =>
          itens.map((_item) =>
            _item != item
              ? _item
              : {
                  ...item,
                  [name]: value,
                }
          )
        );
      },
    };
  };
  return (
    <div className="p-4">
      {itens.map((item, index) => (
        <div
          className="form-group p-4 rounded position-relative"
          style={{ background: "rgba(0,0,0,.1)" }}
          key={index}
        >
          <label
            className="text-danger font-weight-bold cursor-pointer"
            style={{
              position: "absolute",
              right: 16,
              top: 16,
              cursor: "pointer",
            }}
            onClick={() => removeItem(item)}
          >
            Excluir
          </label>
          <label>Cor #{index + 1}</label>

          <div className="form-group">
            <label>Cor principal</label>
            <input
              className="form-control"
              type="color"
              {...register("mainColor", item)}
            />
          </div>
          <div className="form-group">
            <label>Nome da cor</label>
            <input
              className="form-control"
              type="input"
              {...register("title", item)}
            />
          </div>
          <div className="form-group">
            <label>Imagem</label>
            <FileUploader
              accept="image/*"
              name="avatar"
              randomizeFilename
              storageRef={firebase.storage().ref("pallets")}
              //   onUploadStart={this.handleUploadStart}
              //   onUploadError={this.handleUploadError}
              onUploadSuccess={async (filename, args) => {
                const { uploadUrl_ } = args;
                const { onChange } = register("url", item);

                const downloadURL = await firebase
                  .storage()
                  .ref("pallets")
                  .child(filename)
                  .getDownloadURL();

                onChange({
                  target: {
                    value: downloadURL,
                  },
                });
              }}
              onUploadError={console.log}
              //   onProgress={this.handleProgress}
            />
          </div>
          <br />
          {item.url && (
            <img
              style={{
                maxHeight: 200,
                width: "auto !important",
              }}
              src={item.url}
            />
          )}
        </div>
      ))}

      <button className="btn btn-primary w-100" onClick={addItem}>
        Adicionar Cor
      </button>
    </div>
  );
};
