import React, { useEffect, useState } from "react";
import "./pallet.scss";
import * as firebase from "firebase/app";

export default ({ pallets = [] }) => {
  const [menuOption, setMenuOption] = useState(0);
  const [color, setColor] = useState(0);
  const changeMenu = (value) => () => setMenuOption(value);
  const labels = pallets.map((i) => i.type);

  const colors = pallets[menuOption] ? pallets[menuOption].colors : [];
  const { url, title } = colors[color] || {};

  function toImage(base64) {
    return new Promise((r) => {
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = base64;
      image.onload = () => r(image);
    });
  }

  async function download(url, name) {
    const img = await toImage(url);
    const urlApi = url.substring(0, url.lastIndexOf("?"));
    const urlSplited = urlApi.split(".");
    const extension = urlSplited[urlSplited.length - 1];
    const canvas = document.createElement("canvas");
    const { width, height } = img;

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    return saveBlobAsFile(
      canvas.toDataURL("image/png"),
      (name + "." + extension).toLowerCase()
    );
  }

  function saveBlobAsFile(base64, fileName) {
    // var base64 = reader.result;
    var link = document.createElement("a");

    document.body.appendChild(link); // for Firefox

    link.setAttribute("href", base64);
    link.setAttribute("download", fileName);
    link.click();
  }

  useEffect(() => {
    setColor(0);
  }, [menuOption]);
  return (
    <div>
      <div className="d-flex flex-row mb-3">
        {labels.map((item, index) => (
          <div
            onClick={changeMenu(index)}
            className={
              "result-option " + (index == menuOption ? " active" : "")
            }
            key={index}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="pallet">
        <div className="pallet-menu">
          {colors.map((c, i) => (
            <div
              className={"pallet-item " + (color == i ? "active" : "")}
              onClick={() => setColor(i)}
            >
              <div
                className="pallet-color"
                style={{
                  backgroundColor: c.mainColor,
                }}
              ></div>
            </div>
          ))}
        </div>
        <div className="pallet-card">
          <div className="pallet-title">{title}</div>
          <img src={url} style={{ maxWidth: 480 }} width="100%" />

          <div className="pallet-text">
            Guarde esta imagem para <b>sempre te ajudar</b> a escolher a{" "}
            <b>melhor opção</b> do seu guarda-roupa!
          </div>

          <div
            // download="pallet.png"
            // href={url}
            onClick={() => download(url, `${labels[menuOption]}_${title}`)}
            target="_blank"
            className="btn btn-outline-primary btn-lg w-100 rounded mt-4 "
          >
            Salvar imagem
          </div>
        </div>
      </div>
      {/* <div class="card  shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white`}>
          <p className="mb-2 text-center">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/fotos-resultados%2F%40cartela_outono_quente.png?alt=media&"
              }
              style={{ maxWidth: 480 }}
              width="100%"
            />
          </p>
        </div>
      </div> */}
    </div>
  );
};
