import React from "react";
import Question from "../../components/question/Question";
import { Link, Redirect } from "react-router-dom";
import { useCurrentQuestion } from "../../hooks/useProducts";

export default ({ match: { params } }) => {
  const { idQuiz } = params;
  const currentQuestion = useCurrentQuestion(idQuiz);

  if (currentQuestion)
    return (
      <Redirect to={"/quiz/" + idQuiz + "/question/" + currentQuestion.id} />
    );
  return <div class="container py-3">Carregando</div>;
};
