import React, { useCallback, useEffect, useState } from "react";
import { useHelpModal } from "../../../hooks/useHelpModal";
import ColorFaceCompare from "./ColorFaceCompare";
import { ColorQuestionContext } from "./ColorQuestionContext";

class TestandoCores {
  cores = [];
  resultados = [];
  compNumber = 0;
  constructor(cores) {
    this.cores = cores;
    this.resultados = [...this.cores];
  }

  getMaxComp() {
    return this.cores.length - 1;
  }

  getComp() {
    if (this.compNumber == this.getMaxComp()) {
      console.log("Vencedora " + this.resultados);
      return;
    }
    const n = this.getCompNumber() * 2;

    return this.resultados[n] + this.resultados[n + 1];
  }

  getCompNumber() {
    return this.compNumber;
  }

  print() {
    console.log("Comparação atual " + this.getCompNumber());
    console.log(this.getComp());
    console.log(this.resultados);
  }

  answer(value) {
    this.resultados.push(value);
    this.compNumber++;
  }
}

export default ({ question, answerQuestion, onSelect }) => {
  const [lastProcess, setLastProcess] = useState(null);

  const [contextState, setContextState] = useState({
    typeInfo: null,

    options: [],
    results: [],
    // video: "/assets/video-1.mp4",
    videos: question.videos || [],
    currentCompareSelected: null,
    currentCompare: null,
    currentCompareOptions: [],
    changeCurrentCompareSelected: (options) =>
      setState({ currentCompareSelected: options }),

    nextCurrentcompare: () => false,

    infoModal: false,
    showInfoModal: () => setState({ infoModal: true }),
    hideInfoModal: () => setState({ infoModal: false }),
  });

  useEffect(() => {
    document.getElementById("root").className = "no-header-fixed";
    return () => {
      document.getElementById("root").className = "";
    };
  }, []);
  useEffect(() => {
    const { typeInfo, options } = question || {};
    setState({
      typeInfo,
      options,
      results: options.map((o) => ({ ...o })),
      currentCompare: 0,
    });
  }, [question]);

  useEffect(() => {
    if (contextState.currentCompare === null) return;
    if (contextState.currentCompare === lastProcess) return;
    setLastProcess(contextState.currentCompare);
    if (contextState.currentCompare === contextState.options.length - 1) {
      answerQuestion(contextState.currentCompareSelected.value)();
      onSelect(contextState.currentCompareSelected.value);
      return;
    }

    const n = contextState.currentCompare * 2;
    const currentCompare = contextState.currentCompare;
    const results = [
      ...contextState.results,
      ...(contextState.currentCompareSelected
        ? [contextState.currentCompareSelected]
        : []),
    ];
    setState({
      currentCompareSelected: results[n],
      currentCompareOptions: [results[n], results[n + 1]],
      results,
      video:
        currentCompare >= (question.videos || []).length
          ? null
          : question.videos[currentCompare],
      nextCurrentcompare: () => {
        setState({ currentCompare: currentCompare + 1 });
      },
      goBack:
        currentCompare == 0
          ? undefined
          : () => {
              setContextState((state) => {
                return {
                  ...state,
                  currentCompare: state.currentCompare - 1,
                  results: state.results.slice(0, state.results.length - 1),
                };
              });
            },
    });
  }, [
    contextState.currentCompare,
    contextState.options,
    lastProcess,
    contextState.results,
  ]);

  const setState = (obj) => {
    setContextState((state) => ({
      ...state,
      ...obj,
    }));
  };

  const show = useHelpModal(
    contextState.typeInfo,
    contextState.hideInfoModal,
    question.title,
    contextState.videos // s.map((v) => v.src)
  );
  useEffect(() => {
    if (contextState.infoModal) show();
  }, [contextState.infoModal]);

  return (
    <>
      {/* <pre>{JSON.stringify(contextState.results, null, 2)}</pre> */}
      <ColorQuestionContext.Provider value={contextState}>
        <ColorQuestionContext.Consumer>
          {(context) => <ColorFaceCompare />}
        </ColorQuestionContext.Consumer>
      </ColorQuestionContext.Provider>
    </>
  );
};
