import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Users from "./Users";
import UserView from "./UserView";

export default () => {
  return (
    <Router>
      <Route path="/adm/users" component={Users} />
      <Route path="/adm/users/:id" component={UserView} exact />
    </Router>
  );
};
