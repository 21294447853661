import React, { useEffect } from "react";
import app, { db } from "../../base.js";
import * as firebase from "firebase/app";
export default ({ history }) => {
  useEffect(() => {
    // db.collection('users').add({
    // })
    // console.log(db.ref("/users").limitToLast(20));
  }, []);
  const handleLogin = (provider, type) => async () => {
    try {
      await app.auth().signInWithRedirect(provider);
    } catch (error) {
      if (error.code == "auth/account-exists-with-different-credential") {
        alert(
          `Este e-mail já está sendo utilizando para o login com o ${
            type == "google" ? "Facebook" : "Google"
          } `
        );
      } else alert(error);
    }
  };
  return (
    <div>
      <div
        class="container py-3 h-100vh align-items-center justify-content-start d-flex flex-column"
        style={{ maxWidth: 400 }}
      >
        <h2 className="mb-4 mt-5">Olá :)</h2>
        <img src="/assets/images/login-img.png" className="mb-3 w-100 h-auto" />
        <p className="font-2 mb-3 mt-1">
          Escolha como deseja entrar ou se cadastrar:
        </p>
        <button
          className="btn rounded btn-second btn-login-social"
          onClick={handleLogin(
            new firebase.auth.FacebookAuthProvider(),
            "facebook"
          )}
        >
          <div className="icon">
            <img src="/assets/images/facebook.svg" />
          </div>
          Entrar com Facebook
        </button>
        <button
          className="btn rounded btn-primary btn-login-social mt-2"
          onClick={handleLogin(
            new firebase.auth.GoogleAuthProvider(),
            "google"
          )}
        >
          <div className="icon">
            <img src="/assets/images/google.svg" />
          </div>
          Entrar com Google
        </button>
      </div>
    </div>
  );
};
