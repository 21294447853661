import React, { useRef, useEffect, useState } from "react";
import * as facemesh from "@tensorflow-models/facemesh";
import * as tf from "@tensorflow/tfjs-core";

export default ({ setCanvas, registerAnimate }) => {
  const [count, setCount] = useState(0);
  const canvas = useRef(null);
  const canvas2 = useRef(null);
  const video = useRef(null);
  const model = useRef(null);
  const prediction = useRef(null);
  const videoStream = useRef(null);

  useEffect(() => {
    if (canvas2) setCanvas(canvas2.current);
  }, [canvas2]);
  useEffect(() => {
    return () => {
      if (videoStream.current) videoStream.current();
    };
  }, []);

  const setupCamera = async function () {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: "user",
      },
    });
    video.current.srcObject = stream;

    videoStream.current = () => stream.getTracks()[0].stop();
    return new Promise((resolve) => {
      video.current.onloadedmetadata = () => {
        resolve(video.current);
      };
    });
  };

  const setupCanvas = async () => {
    canvas.current.height = 600;
    canvas.current.width = 600;
  };

  const setupModel = async () => {
    await tf.setBackend("wasm");
    model.current = await facemesh.load({ maxFaces: 1 });
  };

  const calcPrediction = async () => {
    const predictions = await model.current.estimateFaces(video.current);
    if (predictions[0]) prediction.current = predictions[0];
  };

  const animate = async () => {
    setCount((count) => count + 1);
    if (!canvas.current) return;
    var ctx = canvas.current.getContext("2d");

    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
    ctx.drawImage(video.current, 0, 0);
    calcPrediction();
    const { height, width } = canvas.current;
    const c = canvas2.current;
    var cx = canvas2.current.getContext("2d");
    c.width = width;
    c.height = height;

    ctx.save();
    ctx.clearRect(0, 0, width, height);
    const face = prediction.current;

    if (!face) return;

    const { silhouette, noseTip } = face.annotations;
    const points = silhouette;
    const minX = points.map((s) => s[0]).reduce((a, b) => (a < b ? a : b));
    const minY = points.map((s) => s[1]).reduce((a, b) => (a < b ? a : b));
    const maxX = points.map((s) => s[0]).reduce((a, b) => (a > b ? a : b));
    const maxY = points.map((s) => s[1]).reduce((a, b) => (a > b ? a : b));

    const drawByIndexes = (indexes) => {
      ctx.beginPath();
      ctx.moveTo(silhouette[0][0], silhouette[0][1]);
      for (let i = 0; i < silhouette.length; i++) {
        const x = silhouette[i][0];
        const y = silhouette[i][1];
        // if (indexes.includes(i)) {
        // ctx.fillText(pointsToDraw[i_].index, x, y);
        ctx.lineTo(x, y);
        // }
      }
      ctx.closePath();
    };

    drawByIndexes();

    ctx.clip();
    ctx.drawImage(video.current, 0, 0);
    ctx.restore();

    c.width = 800;
    c.height = 800;

    const widthScaled = (maxX - minX) * (800 / (maxY - minY));
    cx.drawImage(
      canvas.current,
      minX,
      minY,
      maxX - minX,
      maxY - minY,
      Math.abs(widthScaled - 800) / 2,
      0,
      widthScaled,
      800
    );

    return new Promise((r) => {
      var clippedImage = new Image();
      clippedImage.onload = () => {
        r();
      };
      clippedImage.src = canvas2.current.toDataURL();
    });
  };

  useEffect(() => {
    Promise.all([setupModel(), setupCamera()])
      .then(setupCanvas)
      .then(() => registerAnimate(animate));
  }, []);

  return (
    <div>
      <p>{count}</p>
      <video
        ref={video}
        // muted
        autoPlay
        playsInline
        style={{
          // display: "none",
          // visibility: "hidden",
          transform: "scaleX(-1)",
          height: "100%",
          position: "absolute",
          top: "-100%",
          // opacity: 0.5,
        }}
      />
      <canvas
        ref={canvas}
        style={{
          display: "none",
          height: "100px",
          width: "100px",
          border: "1px solid black",
        }}
      />
      <canvas
        ref={canvas2}
        style={{ height: "100px", border: "1px solid black" }}
      />
    </div>
  );
};
