import React from "react";
import Value from "../app-config/Value";
import { Link } from "react-router-dom";

export default () => {
  return (
    <div class="card mb-4 shadow-sm rounded bg-complement option">
      <div class={`card-body bg-white`}>
        <Value field="call-to-action-home" className="text-black font-3" />
        <Value
          field="call-to-action-home-2"
          className="text-second-black mt-2 mb-2 font-1"
        />

        <Link
          to={"me-ajuda"}
          className="btn btn-second btn-lg w-100 rounded mt-4 "
        >
          Sim, quero!
        </Link>
      </div>
    </div>
  );
};
