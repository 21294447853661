import React, { useEffect } from "react";
import * as facemesh from "@tensorflow-models/facemesh";
import * as tf from "@tensorflow/tfjs-core";
import Mesh from "../../components/simulation/Mesh/Mesh";
import MeshControl from "../../components/simulation/Mesh/MeshControl";
import Scene from "../../components/simulation/Mesh/Scene";
import LightControl from "../../components/simulation/Mesh/LightControl";
import Face from "../../components/simulation/Mesh/Face";
import FaceSkin from "../../components/simulation/Mesh/FaceSkin";
export default class T extends React.Component {
  state = {
    colors: ["#EC849F", "#BF1F77", "#8B0236", "#BF1F77"],
    started: true,
    canvas: null,
  };

  animations = [];

  constructor() {
    super();
    this.registerAnimate = this.registerAnimate.bind(this);
  }

  componentDidMount() {
    // this.setupPage();
    this.animate();
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.requestID);
  }

  registerAnimate(animate, deleteIn) {
    this.animations.push(animate);

    if (deleteIn)
      this.timout = setTimeout(() => {
        this.animations = this.animations.filter((a) => a !== animate);
      }, deleteIn);
  }

  updateColors(colors) {
    this.setState({ colors });
  }

  onStart() {
    this.setState({ started: true });
  }

  setCanvas(canvas) {
    this.setState({ canvas });
    this.scene.updateCanvas(canvas);
  }

  setCanvasFace(canvas) {
    this.scene.updateCanvasFace(canvas);
    if (this.faceSkin) this.faceSkin.updateCanvas(canvas);
  }

  updateLight(...args) {
    this.scene.updateLight(...args);
  }

  async animate() {
    let request;
    if (window.requestAnimationFrame) {
      request = window.requestAnimationFrame;
    } else if (window.webkitRequestAnimationFrame) {
      request = window.webkitRequestAnimationFrame;
    } else if (window.mozRequestAnimationFrame) {
      request = window.mozRequestAnimationFrame;
    } else if (window.oRequestAnimationFrame) {
      request = window.oRequestAnimationFrame;
    } else if (window.msRequestAnimationFrame) {
      request = window.msRequestAnimationFrame;
    }
    await Promise.all(this.animations.map((animation) => animation()));

    if (request) this.requestID = request(this.animate.bind(this));
  }

  render() {
    const { c1, c2, c3, c4 } = this.props;
    const hasColors = !!c1;
    const colors = [c1, c2, c3, c4];
    return (
      <div className="h-100 w-100">
        {this.state.started && (
          <div
            style={{
              position: "absolute",
              right: 0,
              // display: "none",
              border: "1px solid black",
            }}
          >
            <Face
              registerAnimate={this.registerAnimate}
              setCanvas={(canvas) => this.setCanvasFace(canvas)}
            />

            <LightControl
              colors={this.state.colors}
              onUpdate={(x, y) => this.updateLight(x, y)}
            />
            <div>
              <Mesh
                colors={hasColors ? colors : this.state.colors}
                registerAnimate={this.registerAnimate}
                setCanvas={(canvas) => this.setCanvas(canvas)}
              />
            </div>
            <MeshControl
              colors={this.state.colors}
              updateColors={(colors) => this.updateColors(colors)}
            />
            {/* <FaceSkin
              ref={(ref) => (this.faceSkin = ref)}
              registerAnimate={this.registerAnimate}
              // setCanvas={(canvas) => this.setCanvasFace(canvas)}
            /> */}
          </div>
        )}

        <Scene
          ref={(ref) => (this.scene = ref)}
          onStart={() => this.onStart()}
          registerAnimate={(animate) => this.registerAnimate(animate)}
        />
      </div>
    );
  }
}
