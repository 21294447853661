import React, { useState, useEffect } from "react";

export default ({ colors, updateColors }) => {
  const [_c1, _c2, _c3, _c4] = colors;
  const setC1 = (value) => updateColors([value, _c2, _c3, _c4]);
  const setC2 = (value) => updateColors([_c1, value, _c3, _c4]);
  const setC3 = (value) => updateColors([_c1, _c2, value, _c4]);
  const setC4 = (value) => updateColors([_c1, _c2, _c3, value]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-1">
        C1{" "}
        <input
          type="color"
          className="flex-1 ml-1"
          value={_c1}
          onChange={(e) => setC1(e.target.value)}
        />{" "}
        <br />
      </div>
      <div className="d-flex flex-1 mt-1">
        C2{" "}
        <input
          type="color"
          className="flex-1 ml-1"
          value={_c2}
          onChange={(e) => setC2(e.target.value)}
        />{" "}
        <br />
      </div>
      <div className="d-flex flex-1 mt-1">
        C3{" "}
        <input
          type="color"
          className="flex-1 ml-1"
          value={_c3}
          onChange={(e) => setC3(e.target.value)}
        />{" "}
        <br />
      </div>
      <div className="d-flex flex-1 mt-1">
        C4{" "}
        <input
          type="color"
          className="flex-1 ml-1"
          value={_c4}
          onChange={(e) => setC4(e.target.value)}
        />{" "}
      </div>
    </div>
  );
};
