import React from "react";
import { Switch, Route } from "react-router-dom";
import Question from "./Question";
import QuizStart from "./QuizStart";
import QuizFinish from "./QuizFinish";
import QuizIntro from "./QuizIntro";
import EditQuiz from "./EditQuiz";
export default () => {
  return (
    <Switch>
      <Route path="/quiz/:idQuiz/start" component={QuizStart} />
      <Route path="/quiz/:idQuiz/intro" component={QuizIntro} />
      <Route path="/quiz/:idQuiz/finish" component={QuizFinish} />
      <Route path="/quiz/:idQuiz/question/:idQuestion" component={Question} />

      <Route path="/quiz/:idQuiz/edit" component={EditQuiz} />

      <Route path="/quiz/:idQuiz" component={QuizStart} />
    </Switch>
  );
};
