import { FACES as indices, UVS as texCoords } from "./geometry.js";
import {
  BufferGeometry,
  BufferAttribute,
  Vector3,
  Triangle,
  Matrix4,
} from "three";
const mask = [
  [-326.9149169921875, 228.96929931640625, -15.633119583129883],
  [-355.78759765625, 228.8558807373047, -14.05860710144043],
  [-380.18865966796875, 232.32684326171875, -7.698081016540527],
  [-402.3847351074219, 240.41102600097656, 3.4249496459960938],
  [-416.5312805175781, 253.20005798339844, 16.597883224487305],
  [-425.1947326660156, 269.661376953125, 29.602140426635742],
  [-429.7540283203125, 287.46734619140625, 43.57163619995117],
  [-431.83953857421875, 310.1156921386719, 55.12736511230469],
  [-431.5177001953125, 331.6412658691406, 61.12833786010742],
  [-431.181396484375, 353.490966796875, 63.24945068359375],
  [-429.1661682128906, 377.129638671875, 62.31435012817383],
  [-423.88427734375, 401.9312438964844, 58.25144958496094],
  [-415.8317565917969, 422.35107421875, 49.587738037109375],
  [-406.21624755859375, 438.08453369140625, 39.520206451416016],
  [-393.4772644042969, 452.42535400390625, 31.133485794067383],
  [-382.2156066894531, 462.5998840332031, 24.611804962158203],
  [-370.0436096191406, 471.6031799316406, 17.90780258178711],
  [-355.6713562011719, 478.0101318359375, 13.620655059814453],
  [-336.8354187011719, 480.52130126953125, 12.011137008666992],
  [-317.8661804199219, 479.2272644042969, 13.738747596740723],
  [-303.14105224609375, 473.7292175292969, 18.06343650817871],
  [-290.4676818847656, 465.5840759277344, 24.87653350830078],
  [-278.6258239746094, 456.10345458984375, 31.393234252929688],
  [-265.0252380371094, 442.6455383300781, 39.82175827026367],
  [-254.3905487060547, 427.54595947265625, 50.02971267700195],
  [-244.95652770996094, 407.7655944824219, 58.727210998535156],
  [-237.90771484375, 383.3409423828125, 62.803497314453125],
  [-234.12020874023438, 359.9827880859375, 63.75818634033203],
  [-232.1820068359375, 338.285888671875, 61.627872467041016],
  [-230.1531219482422, 316.7886657714844, 55.633644104003906],
  [-230.29188537597656, 294.218505859375, 44.06208801269531],
  [-233.33486938476562, 276.1947021484375, 30.047826766967773],
  [-240.3345184326172, 259.239990234375, 17.061248779296875],
  [-253.1838836669922, 245.46737670898438, 3.7993991374969482],
  [-274.43890380859375, 235.8943634033203, -7.368748664855957],
  [-298.2613220214844, 230.7989501953125, -13.903939247131348],
];
class FaceMeshFaceGeometry extends BufferGeometry {
  constructor(options = {}) {
    super();

    this.useVideoTexture = options.useVideoTexture || false;
    this.normalizeCoords = options.normalizeCoords || false;
    this.flipped = false;
    this.positions = new Float32Array(468 * 3);
    this.uvs = new Float32Array(468 * 2);
    this.setAttribute("position", new BufferAttribute(this.positions, 3));
    this.setAttribute("uv", new BufferAttribute(this.uvs, 2));
    this.setUvs();
    this.setIndex(indices);
    this.computeVertexNormals();
    this.applyMatrix4(new Matrix4().makeScale(10, 10, 10));
    this.p0 = new Vector3();
    this.p1 = new Vector3();
    this.p2 = new Vector3();
    this.triangle = new Triangle();
    this.attributes.position.needsUpdate = true;
  }

  setUvs() {
    for (let j = 0; j < 468; j++) {
      this.uvs[j * 2] = this.flipped ? 1 - texCoords[j][0] : texCoords[j][0];
      this.uvs[j * 2 + 1] = 0; // 1 - texCoords[j][1];
    }
    this.getAttribute("uv").needsUpdate = true;
  }

  setVideoUvs() {
    let ptr = 0;
    for (let j = 0; j < 468 * 2; j += 2) {
      this.uvs[j] = this.flipped
        ? this.positions[ptr] / this.w + 0.5
        : 1 - (this.positions[ptr] / this.w + 0.5);
      this.uvs[j + 1] = this.positions[ptr + 1] / this.h + 0.5;
      ptr += 3;
    }
    this.getAttribute("uv").needsUpdate = true;
  }

  setSize(w, h) {
    this.w = w;
    this.h = h;
  }

  update(face, cameraFlipped) {
    let ptr = 0;

    if (localStorage.mask) return;
    localStorage.mask = JSON.stringify(face.annotations.silhouette);
    console.log(face.annotations.silhouette);
    for (const p of face.annotations.silhouette) {
      this.positions[ptr] = cameraFlipped
        ? p[0] + 0.5 * this.w
        : p[0] - 0.5 * this.w;
      this.positions[ptr + 1] = this.h - p[1] - 0.5 * this.h;
      this.positions[ptr + 2] = -p[2];
      ptr += 3;
    }
    if (this.useVideoTexture) {
      this.setVideoUvs();
      if (this.normalizeCoords) {
        let ptr = 0;
        const ar = this.h / this.w;
        const scale = 2 * Math.sqrt(this.w / 1000);
        for (const p of face.scaledMesh) {
          this.positions[ptr] = scale * (p[0] / this.w + 0.5);
          this.positions[ptr + 1] = scale * (-p[1] / this.h + 0.5) * ar;
          this.positions[ptr + 2] = scale * (-p[2] / 500);
          ptr += 3;
        }
      }
    } else {
      if (cameraFlipped !== this.flipped) {
        this.flipped = cameraFlipped;
        this.setUvs();
      }
    }
    this.attributes.position.needsUpdate = true;
    this.computeVertexNormals();
  }

  track(id0, id1, id2) {
    const points = this.positions;
    this.p0.set(points[id0 * 3], points[id0 * 3 + 1], points[id0 * 3 + 2]);
    this.p1.set(points[id1 * 3], points[id1 * 3 + 1], points[id1 * 3 + 2]);
    this.p2.set(points[id2 * 3], points[id2 * 3 + 1], points[id2 * 3 + 2]);
    this.triangle.set(this.p0, this.p1, this.p2);
    const center = new Vector3();
    this.triangle.getMidpoint(center);
    const normal = new Vector3();
    this.triangle.getNormal(normal);
    const matrix = new Matrix4();
    const x = this.p1.clone().sub(this.p2).normalize();
    const y = this.p1.clone().sub(this.p0).normalize();
    const z = new Vector3().crossVectors(x, y);
    const y2 = new Vector3().crossVectors(x, z).normalize();
    const z2 = new Vector3().crossVectors(x, y2).normalize();
    matrix.makeBasis(x, y2, z2);
    return { position: center, normal, rotation: matrix };
  }
}

export { FaceMeshFaceGeometry };
