import React, { useRef, useEffect, useState, Component } from "react";

import * as facemesh from "@tensorflow-models/facemesh";
import * as tf from "@tensorflow/tfjs-core";

// import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
// import "@tensorflow/tfjs-backend-webgl";

const CanvasRender = ({
  height,
  c1,
  c2,
  c3,
  c4,
  frameId,
  center,
  active,
  points,
  stream,
  onSelect,
}) => {
  const video = useRef(null);
  const canvas = useRef(null);

  useEffect(() => {
    video.current.srcObject = stream;
    // video.current.videoHeight = video.current.offsetHeight *
  }, [stream]);

  useEffect(() => {
    if (!video.current) return;
    canvas.current.height = video.current.offsetHeight;
    canvas.current.width = video.current.offsetWidth;
    // video.current.play();
  }, [video.current]);

  useEffect(() => {
    if (points && points.length > 0) renderPrediction1();
  }, [frameId]);
  const renderPrediction1 = async () => {
    canvas.current.width = video.current.offsetWidth;
    canvas.current.height = video.current.offsetHeight;
    const currentCanvas = canvas.current;
    if (!currentCanvas) return;
    const ctx = currentCanvas.getContext("2d");

    const drwaTriagle = (color, start, end) => {
      ctx.beginPath();
      ctx.moveTo(...start);
      ctx.lineTo(currentCanvas.width / 2, currentCanvas.height / 2);
      ctx.lineTo(...end);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    };

    const diffSize =
      ((currentCanvas.offsetWidth - window.innerWidth) *
        (currentCanvas.width / currentCanvas.offsetWidth)) /
      2;
    let initialWidth = Math.max(0, diffSize);
    const endWidth = currentCanvas.width - initialWidth;

    ctx.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
    drwaTriagle(c1, [endWidth, 0], [endWidth, currentCanvas.height]);
    drwaTriagle(c2, [initialWidth, 0], [endWidth, 0]);
    drwaTriagle(c3, [initialWidth, 0], [initialWidth, currentCanvas.height]);
    drwaTriagle(
      c4,
      [initialWidth, currentCanvas.height],
      [endWidth, currentCanvas.height]
    );

    ctx.globalCompositeOperation = "destination-out";

    ctx.beginPath();

    let _points = [];
    for (let i = 0; i < points.length; i++) {
      const length = points[i].values.length;
      const [x, y] = points[i].values.reduce(
        (a, b) => [a[0] + b[0], a[1] + b[1]],
        [0, 0]
      );
      _points.push([x / length, y / length]);
    }
    const minX = _points.map((s) => s[0]).reduce((a, b) => (a < b ? a : b));
    const minY = _points.map((s) => s[1]).reduce((a, b) => (a < b ? a : b));
    const maxX = _points.map((s) => s[0]).reduce((a, b) => (a > b ? a : b));
    const maxY = _points.map((s) => s[1]).reduce((a, b) => (a > b ? a : b));
    const addX = currentCanvas.width / 2 - minX - (maxX - minX) / 2;
    const addY = currentCanvas.height / 2 - maxY - (minY - maxY) / 2;

    const transformX = -video.current.videoWidth / 2 + (minX + maxX) / 2;
    const transformY = video.current.videoHeight / 2 - (minY + maxY) / 2;
    video.current.style.transform = `translateX(${transformX}px) translateY(${transformY}px) scaleX(-1)`;

    ctx.moveTo(_points[0][0] + addX, _points[0][1] + addY);
    for (let i = 0; i < _points.length; i++) {
      const [x, y] = _points[i];
      const pointx = x + addX;
      ctx.lineTo(x + addX, y + addY);
    }
    ctx.closePath();
    ctx.fill();
    ctx.globalCompositeOperation = "source-over";
  };

  return (
    <div
      className="position-relative  align-items-center justify-content-center d-flex"
      style={{ height }}
    >
      <div
        onClick={onSelect}
        className={`color-camera-border ${active ? "active" : ""}`}
        style={{
          position: "absolute",
          width: window.innerWidth,
          maxWidth: canvas.current && canvas.current.width,
          height: "100%",
          zIndex: 999,
          cursor: "pointer",
        }}
      >
        <div
          className="selected-option position-absolute w-100  justify-content-start"
          style={{
            bottom: -1,
            left: 0,
            zIndex: 0,
            zIndex: 1001,
          }}
        >
          <div className="btn btn-light color-correct">
            Esta opção ficou melhor para mim
          </div>
        </div>
        <div
          className="hover-option position-absolute w-100  justify-content-start"
          style={{
            bottom: -1,
            left: 0,
            zIndex: 0,
            zIndex: 1001,
          }}
        >
          <div className="btn  color-correct">Selecionar opção</div>
        </div>
      </div>
      <div
        style={{
          overflow: "hidden",
          width: canvas.current && canvas.current.width,
          height: canvas.current && canvas.current.height,
        }}
      >
        <video
          ref={video}
          muted
          autoPlay
          playsInline
          // height={height}
          style={
            {
              // width: "100%",
              // display: "none",
              // visibility: "hidden",
              // height: height,
              // maxHeight: height,
              // opacity: 0.5,
            }
          }
        />
      </div>
      <canvas
        className="position-absolute w-100 "
        ref={canvas}
        style={{ transform: "scaleX(-1)", opacity: 1 }}
      />
    </div>
  );
};
export default class Teste extends Component {
  componentDidMount() {
    this.setupPage();
  }

  points = [];
  state = {
    loading: true,
    frameId: 0,
  };
  componentWillUnmount() {
    if (this.stopVideo) this.stopVideo();

    if (window.cancelAnimationFrame) {
      cancelAnimationFrame(this.frame);
    }
  }

  setupCamera = async function () {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: "user",
      },
    });
    this.video.srcObject = stream;
    this.stopVideo = () => stream.getTracks()[0].stop();
    return new Promise((resolve) => {
      this.video.onloadedmetadata = () => {
        resolve(this.video);
      };
    });
  };

  renderCanvas = async () => {
    await this.renderPrediction1();
    this.setState({
      frameId: this.state.frameId == 100 ? 0 : this.state.frameId + 1,
    });
    let request;
    if (window.requestAnimationFrame) {
      request = window.requestAnimationFrame;
    } else if (window.webkitRequestAnimationFrame) {
      request = window.webkitRequestAnimationFrame;
    } else if (window.mozRequestAnimationFrame) {
      request = window.mozRequestAnimationFrame;
    } else if (window.oRequestAnimationFrame) {
      request = window.oRequestAnimationFrame;
    } else if (window.msRequestAnimationFrame) {
      request = window.msRequestAnimationFrame;
    }
    this.frame = request(() => this.renderCanvas());
  };

  renderPrediction1 = async (...args) => {
    if (!this.model || !this.video) return;

    const predictions = await this.model.estimateFaces(this.video);
    if (predictions.length > 0) {
      const prediction = predictions[0];
      const { silhouette, noseTip } = prediction.annotations;
      this.center = [noseTip[0][0], noseTip[0][1]];

      this.setState({
        loading: false,
      });
      if (this.points.length == 0) {
        this.points = silhouette.map((i, index) => ({
          index,
          values: [i],
        }));
      } else {
        this.points = this.points.map((item) => ({
          index: item.index,
          values:
            item.values.length == 5
              ? [...item.values.slice(1), silhouette[item.index]]
              : item.values.concat([silhouette[item.index]]),
        }));
      }
      const initialLength = this.points[0].values.length;
      const [initialX, initialY] = this.points[0].values.reduce(
        (a, b) => [a[0] + b[0], a[1] + b[1]],
        [0, 0]
      );
      const center = [noseTip[0][0], noseTip[0][1]];

      this.renderInfos = {
        initialLength,
        initialX,
        initialY,
        center,
        points: this.points,
      };
    } else {
      this.center = undefined;
    }
  };
  setupPage = async () => {
    // // await tfjsWasm.setWasmPath(
    // //   "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@latest/dist/tfjs-backend-wasm.wasm"
    // // );

    // await tfjsWasm.setWasmPath(
    //   `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/tfjs-backend-wasm.wasm`
    // );

    await tf.setBackend("webgl");
    await this.setupCamera();
    // this.video.play();

    this.videoWidth = this.video.videoWidth;
    this.videoHeight = this.video.videoHeight;
    // this.video.width = videoWidth;
    // this.video.height = videoHeight;

    // let model = await blazeface.load();

    try {
      this.model = await facemesh.load({ maxFaces: 1 });
      // .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
      throw e;
    }
    window.requestAnimationFrame(() => this.renderCanvas());
  };

  render() {
    return (
      <>
        <div
          className="position-absolute opacity-0 flex-1 w-100 posiive align-items-center justify-content-center"
          // style={{ opacity: 0 }}
        ></div>
        <div
          className="d-flex flex-column position-relative  w-100"
          ref={(ref) => (this.container = ref)}
        >
          {/* <div
          className="h-100 d-block"
          style={{ width: window.innerWidth }}
        ></div> */}
          <video
            ref={(ref) => (this.video = ref)}
            // muted
            autoPlay
            playsInline
            style={{
              // display: "none",
              // visibility: "hidden",
              position: "absolute",
              transform: "scaleX(-1)",
              width: "100%",
              zIndex: 999,
              opacity: 0,
            }}
          />
          {this.state.loading && (
            <div className="loading-page-face">
              <div class="loading"></div>
            </div>
          )}
          {this.props.options.map((item, key) => (
            <div
              key={key}
              style={{ overflow: "hidden" }}
              className="d-flex flex-1 w-100 position-relative align-items-center justify-content-center d-flex"
            >
              <CanvasRender
                stream={this.video && this.video.srcObject}
                {...this.renderInfos}
                {...item}
                onSelect={() => this.props.changeCurrentCompareSelected(item)}
                frameId={this.state.frameId}
                videoWidth={this.videoWidth}
                videoHeight={this.videoHeight}
                active={
                  !this.state.loading &&
                  item == this.props.currentCompareSelected
                }
                height={
                  this.container &&
                  this.container.offsetHeight / this.props.options.length
                }
              />
            </div>
          ))}
          {/* <div className="d-flex flex-1 w-100 position-relative align-items-center justify-content-center d-flex">
          <CanvasRender
            stream={this.video && this.video.srcObject}
            {...this.props}
            videoWidth={this.videoWidth}
            videoHeight={this.videoHeight}
            height={this.container && this.container.offsetHeight / 2}
            {...this.renderInfos}
          />
        </div> */}
        </div>
      </>
    );
  }
}
