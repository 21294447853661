import React, { useEffect } from "react";
import Home from "./pages/Home";
import Help from "./pages/Help";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { AuthProvider } from "./components/auth/Auth";
import Header from "./components/header/Header";
import PrivateRoute from "./components/auth/PrivateRoute";

import { Logout, Login } from "./pages/Login";
import Quiz from "./pages/Quiz";
import Profile from "./pages/Profile";
import "react-notifications-component/dist/theme.css";
import ReactNotification from "react-notifications-component";
import useCurrentUser from "./hooks/useCurrentUser";
import { ModalProvider } from "react-modal-hook";
import * as Sentry from "@sentry/react";
import SimulationTest from "./pages/Simulation/SimulationTest";
import ColorAnalyze from "./pages/Simulation/ColorAnalyze";
import Checkout from "./pages/Checkout/Checkout";
import Admin from "./pages/Admin";
import app, { db } from "./base";
import Instagram from "./components/modal/Instagram";

Sentry.init({
  dsn:
    "https://8a089c3be9404891912239e669cb7c42@o435874.ingest.sentry.io/5395935",
});

const GetOut = () => {
  const show = (window.navigator.userAgent || "")
    .toLowerCase()
    .includes("instagram");
  useEffect(() => {
    if (!show) return;
    document.body.className += " no-scroll ";

    return () => {
      document.body.className = document.body.className.replaceAll(
        " no-scroll ",
        ""
      );
    };
  }, []);

  if (!show) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        zIndex: 999,
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.9)",
      }}
    >
      <img
        src="/assets/undo.png"
        style={{
          position: "absolute",
          top: 20,
          right: 10,
          width: 80,
        }}
      />
      <div className="text-white">
        <span>Para sua melhor experiência, clique no </span>
        <b>botão de 3 pontos</b>
        <span> no canto superior direito da tela e selecione </span>
        <b>"Abrir no Chrome"</b>
        <span> ou </span>
        <b>"Abrir no Safari"</b>
        <span>.</span>
      </div>
    </div>
  );
};

function App() {
  useEffect(() => {
    app
      .auth()
      .getRedirectResult()
      .then(function (result) {
        if (!result || !result.user) return;

        if (window.location.href.includes("login")) window.location = "/";
      })
      .catch((error) => {
        if (error.code == "auth/account-exists-with-different-credential") {
          alert(
            `Este e-mail já está sendo utilizando para o login com outra forma de autenticação`
          );
        } else alert(error);
      });
  }, []);
  return (
    <AuthProvider>
      <ModalProvider>
        <ReactNotification />
        <Router>
          <GetOut />
          <Header />
          <Instagram />
          <main role="main">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/land"
                render={(props) => <Home {...props} forceLand />}
              />
              <Route path="/adm" component={Admin} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/logout" component={Logout} />
              <PrivateRoute path="/quiz" component={Quiz} />
              <Route path="/teste" component={ColorAnalyze} />
              <Route
                path="/set-try"
                component={() => {
                  localStorage.try = true;
                  return null;
                }}
              />
              <PrivateRoute path="/me-ajuda" component={Help} />
              <PrivateRoute path="/perfil" component={Profile} />
              <PrivateRoute path="/checkout/:id" component={Checkout} />
            </Switch>
          </main>
        </Router>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
