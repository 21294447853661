import React, { useEffect } from "react";
import Scene from "../../components/simulation/Scene2";
import * as facemesh from "@tensorflow-models/facemesh";
import * as tf from "@tensorflow/tfjs-core";

export default class T extends React.Component {
  componentDidMount() {
    this.setupPage();
    // setTimeout(() => (window.scene = new Scene()), 2000);
  }
  setupCamera = async function () {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        facingMode: "user",
      },
    });
    this.video.srcObject = stream;

    return new Promise((resolve) => {
      this.video.onloadedmetadata = () => {
        resolve(this.video);
      };
    });
  };

  setupPage = async () => {
    // // await tfjsWasm.setWasmPath(
    // //   "https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@latest/dist/tfjs-backend-wasm.wasm"
    // // );

    // await tfjsWasm.setWasmPath(
    //   `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/tfjs-backend-wasm.wasm`
    // );
    await tf.setBackend("wasm");
    await this.setupCamera();
    this.video.play();

    try {
      this.model = await facemesh.load({ maxFaces: 1 });
      // .catch((e) => console.log(e));
    } catch (e) {
      throw e;
    }
    const canvas = document.getElementById("canvas-a");
    let ctx = canvas.getContext("2d");
    // let ctx2 = this.canvas2.getContext("2d");
    ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
    const prediction = async () => {
      const predictions = await this.model.estimateFaces(
        this.video,
        false,
        true
      );
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const w = this.video.videoWidth;
      const h = this.video.videoHeight;
      const ratio = h / w;
      ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height * ratio);
      const { silhouette, noseTip } = predictions[0].annotations;
      this.center = [noseTip[0][0], noseTip[0][1]];
      ctx.globalCompositeOperation = "destination-out";

      ctx.beginPath();
      ctx.moveTo(silhouette[0][0], silhouette[0][1]);
      // ctx.beginPath();
      // // ctx.moveTo(silhouette[0][0], silhouette[0][1]);
      // ctx.moveTo(0, 0);
      // ctx.lineTo(100, 100);
      // ctx.lineTo(100, 0);
      // ctx.fillStyle = "green";
      // ctx.fill();
      for (let i = 0; i < silhouette.length; i++) {
        const x = silhouette[i][0];
        const y = silhouette[i][1];
        ctx.lineTo(x, y);
      }
      ctx.closePath();
      ctx.fillStyle = "#FF0000";
      ctx.fill();
      return predictions;
    };
    new Scene(prediction, this.video);
    // window.requestAnimationFrame(() => this.renderCanvas());
  };
  render() {
    return (
      <div>
        <video
          ref={(ref) => (this.video = ref)}
          // muted
          id="video-id"
          autoPlay
          playsInline
          style={{
            display: "none",
            // visibility: "hidden",
            transform: "scaleX(-1)",
            height: "100%",
            // opacity: 0.5,
          }}
        />

        <section class="" style={{ display: "none" }}>
          <article class="tile">
            <figure class="tile__figure">
              <img
                src="https://images.unsplash.com/photo-1541737923-4dc81f2d1d41?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3334&q=80"
                class="tile__image"
                alt="My image"
                width="400"
              />
            </figure>
          </article>
        </section>
        <canvas
          id="canvas-a"
          style={{
            position: "absolute",
            right: 0,
            width: 100,
            height: 100,
          }}
        ></canvas>
        <div
          id="container"
          style={{ width: "100%", height: "calc(100vh - 72px)" }}
        ></div>
      </div>
    );
  }
}
