import React, { useEffect } from "react";
import InfoCard from "../../components/card/InfoCard";
import PriceCard from "../../components/card/PriceCard";
import CallToActionCard from "../../components/card/CallToActionCard";
import Testimony from "../../components/testimony/Testimony";
import MainCard from "../../components/card/MainCard";
import { useAllProducts } from "../../hooks/useProducts";
import { useSiteConfig } from "../../hooks/useSiteConfig";
import Value from "../../components/app-config/Value";
import useCurrentUser from "../../hooks/useCurrentUser";
import ContactMe from "../../components/help/ContactMe";
import { formatText } from "../../helper/formatText";

export default ({ forceLand = false }) => {
  const products = useAllProducts();
  const app = useSiteConfig();

  const currentUser = useCurrentUser();
  if (currentUser && !forceLand) {
    const allDone = products.map((p) => p.done).reduce((a, b) => a && b, true);

    return (
      <div class="container py-3">
        {allDone && <ContactMe />}
        <div className="row d-flex align-items-stretch">
          {products.map((product) => (
            <div class="col-md-6 d-flex" key={product.id}>
              <PriceCard
                {...product}
                title={product.name}
                type={product.price > 0 ? "second" : "third"}
                price={product.displayPrice}
              />
            </div>
          ))}
        </div>
        {!allDone && <ContactMe />}
      </div>
    );
  }

  return (
    <div>
      <div class="container py-3 pb-4">
        <div class="album bg-light">
          <MainCard />
        </div>
      </div>

      <div class="album bg-light">
        <div class="container pt-4">
          <div class="row">
            {products.map((product) => (
              <div class="col-md-6" key={product.id}>
                <PriceCard
                  title={product.name}
                  type={product.price > 0 ? "second" : "third"}
                  price={product.displayPrice}
                  description={product.description}
                  id={product.id}
                  image={product.image}
                  buttonText={product.buttonText}
                  done={product.done}
                  about={product.about}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class="album bg-light">
        <div class="container">
          <Value
            field="info-cards-title"
            className="h3 text-second mb-2 font-weight-normal text-center fo"
          />
          <Value
            field="info-cards-title-description"
            className="card-text text-center text-black p-0 font-3 mb-4"
          />
          <div class="row d-flex   align-items-stretch justify-content-center">
            {[
              {
                type: "second",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/cards%2Fcard1.jpg?alt=media&token=702bd246-980e-4d94-a32d-b3971b96f51d",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/cards%2Fcard%202.webp?alt=media&token=40922752-0be2-4459-97a1-800c08568ad3",
              },
              {
                type: "second",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/cards%2Fcard3.jpg?alt=media&token=53988750-b197-4176-9978-f238e45d756a",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/cards%2Fcard4.jpg?alt=media&token=e8ca0606-adad-4a9a-8667-d84ff17d6c8a",
              },
              {
                type: "second",
                image:
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/cards%2Fcard5.jpg?alt=media&token=0eb59bcd-763c-4d53-b0fb-c2e9161d274f",
              },
              {
                image:
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/cards%2Fcard6.jpg?alt=media&token=9bb51d3b-ffea-45e4-af34-6ca0d8c382ad",
              },
            ].map((item, index) => (
              <div class="col-md-4 d-flex">
                <InfoCard
                  title={`info-${index + 1}`}
                  {...item}
                  description={`info-description-${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class="album py-2 bg-light">
        <div class="container  pt-3">
          <div className="d-inline d-md-none">
            <Value
              className="text-second mb-3  font-weight-normal text-center h3"
              field="products-cards-title"
            />
            <Value
              className="mb-4 font-weight-light"
              field="products-cards-description"
            />
          </div>

          <div className="d-flex mb-5 ">
            <div className="flex-1 mr-3 d-flex  justify-content-center">
              <img
                className="w-100 "
                style={{
                  maxWidth: 310,
                  borderRadius: "24px 0",
                }}
                src="https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/about-img.png?alt=media"
              />
            </div>
            <div className="flex-1">
              <div className="d-none d-md-inline">
                <Value
                  className="text-second  font-weight-normal text-center h3"
                  field="products-cards-title"
                />
                <Value
                  className="mb-4 font-weight-light"
                  field="products-cards-description"
                />
              </div>
              <Value
                className="mb-4 flex-1 font-weight-light"
                field="products-cards-description-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="album py-5 bg-light">
        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-5">
              <Testimony
                id={1}
                img={
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/depoimentos%2Ft1.png?alt=media&token=61b1d76a-3a30-430e-a27c-e0d8b7d9d5cf"
                }
              />
            </div>
            <div class="col-md-4  mb-5 mt-1">
              <Testimony
                id={2}
                img={
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/depoimentos%2Ft2.png?alt=media&token=ea482a28-7a4f-48da-b3d4-04b908aa8ca1"
                }
              />
            </div>
            <div class="col-md-4 mt-1">
              <Testimony
                id={3}
                img={
                  "https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/depoimentos%2Ft3.png?alt=media&token=72069ef4-377e-457d-9af8-1ac0ce796dbf"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div class="album bg-light">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <CallToActionCard
                to="/login"
                buttonText="call-to-action-button"
                description={"call-to-action-description"}
              />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <ContactMe />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
