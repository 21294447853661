import { db } from "../base";
import useCurrentUser from "./useCurrentUser";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export function useFinish(productId) {
  const currentUser = useCurrentUser();
  const [result, setResult] = useState();

  const calc = async () => {
    const productsQuestions = await db
      .collection("products")
      .doc(productId)
      .collection("questions")
      .get();

    const product = (
      await db.collection("products").doc(productId).get()
    ).data();

    const questions = [];
    productsQuestions.forEach((item) =>
      questions.push({
        id: item.id,
        ...item.data(),
      })
    );

    const userProduct = db
      .collection("users")
      .doc(currentUser.id)
      .collection("products")
      .doc(productId);

    const userProductData = (await userProduct.get()).data();

    if (userProductData.status == "processing") {
      setResult({
        processing: true,
      });
      return;
    }
    if (
      product.price &&
      product.price > 0 &&
      userProductData.status != "paid"
    ) {
      userProduct.update({
        status: "need-pay",
      });
      setResult({
        redirectToCheckout: true,
      });
      return;
    }
    // debugger;
    let resultType;

    const methods = {
      response: () => {
        const r = userProductData.questions.find(
          (q) => q.id == product.reponseResult
        ).value;
        resultType = r;
      },
      countMax: () => {
        let result = {};
        userProductData.questions.map((userQuestion) => {
          const question = questions.find((f) => f.id == userQuestion.id);

          (userQuestion.value || []).map((q, index) => {
            const option = question.options.find((o) => o.value == q);
            const wight = 2 - index;
            if (result[option.value] != undefined) {
              result[option.value] = result[option.value] + wight;
            } else {
              result[option.value] = wight;
            }
          });
        });

        const primaryResult = Object.keys(result).reduce(
          (a, b) => (result[a] >= result[b] ? a : b),
          0
        );
        const secondResult = Object.keys(result)
          .filter((k) => k != primaryResult)
          .reduce((a, b) => (result[a] >= result[b] ? a : b), 0);

        resultType = [primaryResult, secondResult];
      },
    };

    methods[product.type]();

    let resultObj;

    if (Array.isArray(resultType)) {
      resultObj = await Promise.all(
        resultType.map((type) =>
          db
            .collection("products")
            .doc(productId)
            .collection("results")
            .doc(type)
            .get()
            .then((r) => r.data())
        )
      );
    } else {
      resultObj = [
        (
          await db
            .collection("products")
            .doc(productId)
            .collection("results")
            .doc(resultType)
            .get()
        ).data(),
      ];
    }

    userProduct.update({
      result: resultType,
    });

    if (product.prerequisite) {
      const { result } = (
        await db
          .collection("users")
          .doc(currentUser.id)
          .collection("products")
          .doc(product.prerequisite.productId)
          .get()
      ).data();

      setResult(
        resultObj.map((item) => ({
          ...item,
          resultPrerequisite: result,
        }))
      );
    } else {
      setResult(resultObj);
    }
  };
  useEffect(() => {
    calc();
  }, []);

  return result;
}

export function useQuizResult(productId) {
  const [result, setResult] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const currentUser = useCurrentUser();
  const history = useHistory();

  const lood = async () => {
    const userProduct = await db
      .collection("users")
      .doc(currentUser.id)
      .collection("products")
      .doc(productId)
      .get();

    const { result, status: productStatus } = userProduct.data() || {};

    if (productStatus == "processing") {
      setLoading(false);
      setStatus(productStatus);
      return;
    }
    if (productStatus == "paid") {
      history.push(`/quiz/${productId}/finish`);
      return;
    }

    if (!result) {
      setLoading(false);
      return;
    }

    if (productStatus == "need-pay") {
      setLoading(false);
      setStatus(productStatus);
      return;
    }
    const resultObj = await Promise.all(
      (Array.isArray(result) ? result : [result]).map((r) =>
        db
          .collection("products")
          .doc(productId)
          .collection("results")
          .doc(r)
          .get()
          .then((i) => i.data())
      )
    );

    const product = (
      await db.collection("products").doc(productId).get()
    ).data();

    if (product.prerequisite) {
      const { result } = (
        await db
          .collection("users")
          .doc(currentUser.id)
          .collection("products")
          .doc(product.prerequisite.productId)
          .get()
      ).data();

      setResult(
        resultObj.map((item) => ({
          ...item,
          resultPrerequisite: result,
        }))
      );
    } else {
      setResult(resultObj);
    }
    setLoading(false);
  };
  useEffect(() => {
    lood();
  }, [currentUser]);

  return { result, loading, status };
}
