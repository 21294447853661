import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../base";
import Table from "./components/table/Table";
export default () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    db.collection("users").onSnapshot((data) => {
      setUsers(
        data.docs.map((d) => ({
          ...d.data(),
          id: d.id,
        }))
      );
    });
    // .get()
    // .then((p) => console.log(p.docs.map((d) => d.get())));
  }, []);
  return (
    <div className="container pt-4">
      <div className="row mt-4">
        <Table
          data={users}
          configs={[
            {
              label: "Id",
              field: "id",
            },
            {
              label: "Nome",
              field: "displayName",
            },
            {
              label: "Email",
              field: "email",
            },
            {
              label: "Celular",
              field: "cellphone",
            },
            {
              label: "Gênero",
              field: "gender",
            },
            {
              label: "Ação",
              render: (obj) => {
                return <Link to={`/adm/users/${obj.id}`}>Ver</Link>;
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
