import React, { useEffect, useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import ProfileEdit from "../../components/profile/ProfileEdit";
import { useProduct, useSetPayment } from "../../hooks/useProducts";
import { Redirect } from "react-router-dom";
import { db } from "../../base";
import { addWarningNotification } from "../../Notifications";

const toCurrency = (value) => {
  return `R$ ` + value.toFixed(2).replace(".", ",");
};

const CupomInput = ({ onCupom, onCupomChange }) => {
  const [status, setStatus] = useState("");
  const [cupom, setCupom] = useState("");

  const changeCupom = (e) => {
    setCupom(e);
    onCupomChange(e);
    setStatus("");
    onCupom(undefined);
  };

  const check = async (e) => {
    setStatus("loading");

    try {
      const cumpoName =
        cupom && cupom.toLowerCase ? cupom.toLowerCase() : cupom;
      const [_cupom, uses] = await Promise.all([
        db
          .collection("cupons")
          .doc(cumpoName)
          .get()
          .then((i) => i.data()),
        db
          .collection("cupons")
          .doc(cumpoName)
          .collection("uses")
          .get()
          .then((snap) => {
            return snap.size; // will return the collection size
          }),
      ]);

      let cupomIsValid = !!_cupom;

      if (_cupom && _cupom.maxUsed) {
        cupomIsValid = (uses || 0) < _cupom.maxUsed;
      }

      if (cupomIsValid) {
        onCupom(_cupom);
        setStatus("success");
      } else {
        setStatus("");
        addWarningNotification("Atenção", "Cupom não encontrado");
      }
    } catch (error) {
      setStatus("");
      addWarningNotification("Atenção", "Cupom não encontrado");
    }

    // setTimeout(() => {
    //   setStatus("success");
    //   onCupom({
    //     value: 90,
    //     displayPrice: "R$ 20,00",
    //   });
    // }, 500);
  };

  return (
    <div className="position-relative">
      <input
        className="form-control"
        placeholder="Código de cupom"
        value={cupom}
        onChange={(e) => changeCupom(e.target.value)}
      />
      {status == "loading" && (
        <div className="cupom-btn" style={{ border: "none" }}>
          <div class="loading"></div>
        </div>
      )}
      {status == "" && (
        <div className="cupom-btn" onClick={check}>
          <div className="arrow"></div>
        </div>
      )}
      {status == "success" && (
        <div className="cupom-btn success">
          <div className="check"></div>
        </div>
      )}
    </div>
  );
};

const Step2 = ({ product, setPayment, setRedirect }) => {
  const [cupomText, setCupomText] = useState("");
  const [cupom, setCupom] = useState();
  const total = product.price - (cupom ? cupom.value : 0);
  const totalStr = total <= 0 ? "Grátis" : toCurrency(total);

  const checkout = () => {
    const total = product.price - (cupom ? cupom.value : 0);
    const amount = Math.floor(total * 100);

    if (total <= 0) {
      setPayment(cupomText, amount, cupomText);
      setRedirect(true);
      return;
    }
    var checkout = new window.PagarMeCheckout.Checkout({
      encryption_key: "ek_live_Ed2ErzOLelXt5IzkczPpLumVFjYmwe",
      success: (data) => {
        // console.log(data);
        setPayment(data.token, amount, cupomText);
        setRedirect(true);
      },
      error: function (err) {
        console.log(err);
      },
      close: function () {
        console.log("The modal has been closed.");
      },
    });

    checkout.open({
      paymentMethods: "credit_card",
      amount,
      customerData: "true",
      maxInstallments: 3,
      createToken: "true",
      items: [
        {
          id: product.id,
          title: product.productName,
          unit_price: amount,
          quantity: 1,
          tangible: "false",
        },
      ],
    });
  };

  return (
    <div class={`card mb-4 shadow-sm rounded mt-2 `}>
      <div class={`card-body bg-white p-0 custom-menu`}>
        <h5 class="card-title mb-3 text-primary">
          <b>2- Pagamento e Finalização</b>
        </h5>
        <div className="form-group mb-4">
          <label>Digite o cupom de desconto</label>
          <CupomInput onCupomChange={setCupomText} onCupom={setCupom} />
        </div>

        <div
          className="mb-4"
          style={{
            height: "1.5px",
            width: "100%",
            background: "#BDBDBD",
          }}
        ></div>

        <div className="d-flex flex-row mt-3">
          <div className="flex-1" style={{ color: "#212121" }}>
            Análise Coloração Pessoal
          </div>
          <div className="" style={{ color: "#212121" }}>
            {product.displayPrice}
          </div>
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="flex-1" style={{ color: "#212121" }}>
            Cupom de desconto
          </div>
          <div className="" style={{ color: "#40B356" }}>
            {cupom ? cupom.displayPrice : "-"}
          </div>
        </div>
        <div className="d-flex flex-row mt-3">
          <div
            className="flex-1"
            style={{ fontWeight: "bold", color: "#212121" }}
          >
            Total
          </div>
          <div className="" style={{ fontWeight: "bold", color: "#212121" }}>
            {totalStr}
          </div>
        </div>
        <div className="mt-4 w-100">
          <button
            className="btn btn-primary w-100 bnt-lg"
            // onClick={save}
            style={{
              height: 40,
              borderRadius: 8,
              // opacity: enable() ? 1 : 0.5,

              height: 48,
              width: "100%",
            }}
            onClick={checkout}
            disabled={cupomText.length > 0 && !cupom}
          >
            {total !== 0 ? "Ir para o pagamento" : "Ver resultado"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Step1 = ({ checkout }) => {
  return (
    <ProfileEdit
      className="mt-2"
      title={
        <h5 class="card-title mb-3 text-primary">
          <b>1- Dados Pessoais</b>
        </h5>
      }
      buttonStyle={{
        height: 48,
        width: "100%",
      }}
      buttonText="Avançar"
      valid={(currentUser, gender, cellphone) =>
        gender != "" && cellphone.length >= 1
      }
      onSuccess={() => checkout()}
    />
  );
};

export default ({ match: { params } }) => {
  const { id } = params;

  const [step, setStep] = useState(1);
  const [redirect, setRedirect] = useState(false);
  const product = useProduct(id);
  const setPayment = useSetPayment(id);

  if (!product) return <p></p>;

  if (redirect) return <Redirect to={`/quiz/${id}/finish`} />;
  return (
    <div className="p-3">
      <div className="text-black mb-4 font-weight-normal text-center">
        <b>Complete</b> os passos abaixo para ter o resultado da sua{" "}
        <b>Análise de Coloração Pessoal</b>.
      </div>
      <Stepper step={step} changeStep={() => setStep(1)} />
      {step == 1 && <Step1 checkout={() => setStep(2)} />}
      {step == 2 && (
        <Step2
          setPayment={setPayment}
          setRedirect={setRedirect}
          product={product}
        />
      )}
    </div>
  );
};
