import React, { useState } from "react";
import { useQuizResult } from "../../hooks/uesFinishQuestion";
import QuizFinish from "../quiz/QuizFinish";
import Value from "../app-config/Value";
import { Link } from "react-router-dom";
import { useStartProduct } from "../../hooks/useProducts";
import ContactMe from "../help/ContactMe";
import Checkout from "../../pages/Checkout/Checkout";

export default ({ productId, name = "", hideButton = false }) => {
  const { result, loading, status } = useQuizResult(productId);
  const [meunoOption, setMenuOption] = useState(0);
  const menu = ["Estilo Primário", "Estilo Secundário"];
  const changeMenu = (value) => () => setMenuOption(value);
  console.log("status", "status", status);
  const isMultipleResults = Array.isArray(result) && result.length > 1;

  const start = useStartProduct(productId);
  if (loading) return null;
  if (status == "need-pay")
    return (
      <div>
        <div class="card mb-4 shadow-sm rounded nt">
          <div class={`card-body bg-white p-0 custom-menu text-center`}>
            <div className="font-3 text-left">
              Aguardando confirmação do <b>pagamento</b>.
            </div>
            <img className="mt-3" src="/assets/images/clock.svg" />
          </div>
        </div>{" "}
        <div class="card mb-4 shadow-sm rounded nt">
          <div class={`card-body bg-white p-0 custom-menu text-center`}>
            <div className="font-3 text-left">
              Se você ainda <b>não finalizou</b> o pagamento, <b>clique</b> no
              botão abaixo.
            </div>
            <Link
              to={"/checkout/" + productId}
              className="btn btn-primary float-right w-100 mt-3"
              style={{
                height: 48,
                borderRadius: 8,
              }}
            >
              Ir para pagamento{" "}
            </Link>
          </div>
        </div>
      </div>
    );

  if (status == "processing")
    return (
      <div class="card mb-4 shadow-sm rounded nt">
        <div class={`card-body bg-white p-0 custom-menu text-center`}>
          <div className="font-3 text-left">
            Estamos analisando os seus <b>resultados</b> e em até <b>24h</b>{" "}
            você receberá a sua <b>Análise de Coloração Pessoal</b> completa!
          </div>
          <img className="mt-3" src="/assets/images/clock.svg" />
        </div>
      </div>
    );
  if (!result)
    return (
      <>
        <div class="card mb-4 shadow-sm rounded nt">
          <div class={`card-body bg-white p-0 custom-menu`}>
            <Value
              field="call-to-action-product"
              className="text-black font-3"
              args={{ name }}
            />
            <div className="w-100 text-center mb-3 mt-3">
              <img src="/assets/images/prancheta.svg" />
            </div>
            <div
              onClick={start}
              className="btn btn-primary btn-lg w-100 rounded mt-2 "
            >
              Fazer a analise
            </div>
          </div>
        </div>

        <ContactMe />
      </>
    );
  return (
    <QuizFinish
      finish={result[meunoOption]}
      hideButton={hideButton}
      hideTitle
      menu={
        isMultipleResults ? (
          <div className="d-flex flex-row mb-3">
            {menu.map((item, index) => (
              <div
                onClick={changeMenu(index)}
                className={
                  "result-option " + (index == meunoOption ? " active" : "")
                }
                key={index}
              >
                {item}
              </div>
            ))}
          </div>
        ) : (
          false
        )
      }
    />
  );
};
