import React from "react";
import { formatText } from "../../helper/formatText";
import { Link } from "react-router-dom";

export default ({ question, answerQuestion, goBack, goNext, productId }) => {
  return (
    <div>
      <h3 className="text-primary mb-4 font-weight-normal text-center">
        {question.title}
      </h3>
      <div class="card mb-4 shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white`}>
          <p class="card-text mb-2 text-black font-2 mb-0 ">
            {formatText(question.question)}
          </p>

          <div className="row">
            {(question.options || []).map((option, index) => (
              <div
                onClick={answerQuestion(option.value)}
                className={`col-${
                  question.half ? "6" : "12"
                } d-flex flex-row cursor-pointer mt-3`}
                key={index}
              >
                <div
                  className={
                    "custom-check mr-1 mt-1 " +
                    (question.value &&
                    question.value.includes &&
                    question.value.includes(option.value)
                      ? " active"
                      : "")
                  }
                >
                  {question.value &&
                  question.value.includes &&
                  question.value.includes(option.value)
                    ? question.value.indexOf(option.value) + 1
                    : ""}
                </div>
                {option.text && (
                  <div className="flex-1 text-muted pr-2">{option.text}</div>
                )}
                {option.image && (
                  <div className="flex-1 text-muted card-img-top background-image">
                    <img src={option.image} width="100%" />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="">
            {goBack && (
              <button
                type="button"
                className="btn btn-sm back-button rounded mt-4 px-3 d-inline"
                onClick={goBack}
              >
                Anterior
              </button>
            )}
            {goNext ? (
              <button
                type="button"
                disabled={!question.value || question.value.length !== 2}
                className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right d-inline"
                onClick={goNext}
              >
                Continuar
              </button>
            ) : !question.value || question.value.length !== 2 ? (
              <button
                type="button"
                disabled={!question.value}
                className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right d-inline"
              >
                Finalizar
              </button>
            ) : (
              <Link
                // disabled={!question.value}
                // style={{ opacity: !question}}

                to={`/quiz/${productId}/finish`}
              >
                <button className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right">
                  Finalizar
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
