import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { db } from "../../base";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useSiteConfigReload } from "../../hooks/useSiteConfig";
import { addSuccessNotification } from "../../Notifications";

export default ({}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useCurrentUser();
  const reload = useSiteConfigReload();
  const [instagram, setInstagram] = useState("@");

  console.log(currentUser);
  useEffect(() => {
    if (currentUser && !currentUser.instagramShowed) setOpen(true);
  }, [currentUser]);

  const onHide = () => {
    setOpen(false);
    db.collection("users").doc(currentUser.id).update({
      instagramShowed: true,
    });
  };

  const save = () => {
    setLoading(true);
    db.collection("users")
      .doc(currentUser.id)
      .update({
        instagramShowed: true,
        instagram,
      })
      .then((_) => {
        reload();
        addSuccessNotification("Salvo", "Informações salvas com sucesso");
        setLoading(false);
        onHide();
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Modal
      dialogClassName="modal-rounded"
      show={open}
      onHide={onHide}
      centered={true}
    >
      <Modal.Body>
        <div>
          <div className="d-flex align-items-center">
            <div className="flex-1">Instagram</div>
            <div className="p-3" onClick={onHide}>
              <img src="/assets/images/close.svg" height={16} />
            </div>
          </div>
          <div className="flex-1">
            <div className="form-group">
              <label>Quero te conhecer! Deixe aqui seu instagram:</label>
              <input
                className="form-control bg-white"
                onChange={(e) => setInstagram(e.target.value)}
                value={instagram}
              />
            </div>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-default flex-1 mr-2  mt-4"
              onClick={onHide}
              disabled={loading}
              style={{
                border: "1px solid",
              }}
            >
              Agora não
            </button>
            <button
              onClick={save}
              className="btn btn-primary flex-1 ml-2 mt-4"
              disabled={instagram.length < 2 || loading}
            >
              Salvar
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
