import React from "react";
import { Modal } from "react-bootstrap";
import { formatText } from "../../helper/formatText";
import Videos from "./Videos";

const InfoTable = ({ title, itens }) => (
  <div className="font-1 text-second-black mb-4">
    <div className="text-black font-weight-bold mb-2">{title}</div>

    <div className="d-flex flex-row  font-weight-bold">
      <div className="flex-1 text-black text-center">pele</div>
      <div className="flex-1 text-black text-center">manchas</div>
      <div className="flex-1 text-black text-center">olheiras</div>
      <div className="flex-1 text-black text-center">boca</div>
    </div>
    <div className="d-flex flex-row ">
      {itens.map((i) => (
        <div
          key={i}
          className="flex-1 text-black text-center"
          style={{ whiteSpace: "nowrap" }}
        >
          {i}
        </div>
      ))}
    </div>
  </div>
);

export default ({ onHide, type, title, videos }) => {
  if (type == "video")
    return (
      <Modal
        dialogClassName="modal-rounded"
        show={true}
        onHide={onHide}
        centered={true}
      >
        <Modal.Body>
          <div className="pt-4">
            <div className="text-black text-center font-weight-bold font-3">
              {title}
            </div>

            <Videos videos={videos} />
            <div
              className="btn btn-primary btn-lg w-100  mt-4 font-1"
              onClick={onHide}
            >
              Ok, entendi
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  if (type == "colorInfo")
    return (
      <Modal
        dialogClassName="modal-rounded"
        show={true}
        onHide={onHide}
        centered={true}
      >
        <Modal.Body>
          <div className="pt-4">
            <div className="text-black text-center font-weight-bold font-3">
              Comparações de Cores - Orientação
            </div>
            <div className="mt-3 mb-3 text-second-black">
              Nas comparações a seguir, para escolher as melhores opções para
              você, <b>observe</b>:
            </div>
            <InfoTable
              title="COR ERRADA"
              itens={["opaca", "+ aparentes", "+ escuras", "pálida"]}
            />
            <InfoTable
              title="COR CERTA"
              itens={["uniforme", "rosadas", "- aparentes", "avermelhada"]}
            />

            <div className="text-second-black mb-1">Exemplo:</div>
            <div className="text-second-black mb-t">
              Para rever esta orientação, clique no ícone: “
              <img src="/assets/images/help.svg" width="24px" />”
            </div>

            <div
              className="btn btn-primary btn-lg w-100  mt-4 font-1"
              onClick={onHide}
            >
              Ok, entendi
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  return (
    <Modal
      dialogClassName="modal-rounded"
      show={true}
      onHide={onHide}
      centered={true}
    >
      <Modal.Body>
        <div className="pt-4">
          <div className="text-black text-center font-weight-bold font-3">
            Temperatura de pele - Orientação
          </div>

          <Videos videos={videos} />

          <div className="mt-3 mb-3 text-second-black">
            {formatText(
              "Para escolher a melhor opção para você, *analise* quais cores deixam: \\n\\n *1-* Suas *pálpebras e olheiras* mais *claras*; \\n*2-* As *manchas e marcas menos aparentes*; \\n*3-* Sua *boca mais avermelhada*; \\n*4-* Seu *rosto* com aspecto de *maquiagem natural*."
            )}
          </div>

          <div
            className="text-second-black mb-t mt-6"
            style={{
              marginTop: 24,
            }}
          >
            Para rever esta orientação, clique no ícone: “
            <img src="/assets/images/help.svg" width="24px" />”
          </div>
          <div
            className="btn btn-primary btn-lg w-100  mt-4 font-1"
            onClick={onHide}
          >
            Ok, entendi
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
