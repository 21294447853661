import React, { useState, useEffect } from "react";
import ColorCamera from "./colors-face-compare/";
import { formatText } from "../../helper/formatText";
import { Link, Redirect } from "react-router-dom";
import Test from "./Test";
import { useHelpModal } from "../../hooks/useHelpModal";

export default ({ productId, question, answerQuestion, goBack, goNext }) => {
  const [selectionOpen, setSelectionOpen] = useState(false);
  const selected = (question.options || []).find(
    (option) => question.value == option.value
  );

  if (selectionOpen)
    return (
      <ColorCamera
        question={question}
        answerQuestion={answerQuestion}
        onSelect={() => setSelectionOpen(false)}
      />
    );
  // if (selected && !goNext) {
  //   debugger;
  //   return <Redirect to={`/quiz/${productId}/finish`} />;
  // }
  if (selected)
    return (
      <div class="container py-3">
        <h3 className="text-primary mb-4 font-weight-normal text-center">
          {question.title}
        </h3>
        <div
          class="card mb-4 shadow-sm rounded bg-complement option"
          style={{ height: "calc(100vh - 210px)" }}
        >
          <div class={`card-body bg-white d-flex flex-column`}>
            <p class="card-text text-black font-3 mb-4 ">
              {formatText(question.resultDescription, {
                color: selected.value,
              })}
            </p>
            <div className="color mb-4 text-center">
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/${selected.value}.svg?alt=media`}
                width="56px"
              />
            </div>
            <div className="flex-1">
              Se estiver em dúvida sobre as opções que selecionou,{" "}
              <a href="#" onClick={answerQuestion(null)}>
                clique aqui{" "}
              </a>
              para refazer as comparações antes de continuar a análise.
            </div>
            {goNext ? (
              <button
                className="btn btn-primary btn-lg  rounded w-100"
                onClick={goNext}
              >
                Continuar análise
              </button>
            ) : (
              <Link
                to={`/quiz/${productId}/finish`}
                className="btn btn-primary btn-lg  rounded w-100"
                // onClick={goNext}
              >
                Finalizar análise
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  if (1 == 1 + 0)
    return (
      <div class="container py-3">
        <h3 className="text-primary mb-4 font-weight-normal text-center">
          {question.title}
        </h3>
        <div class="card mb-4 shadow-sm rounded bg-complement option">
          <div class={`card-body bg-white`}>
            <p class="card-text text-second-black font-3 font-weight-bold mb-2">
              {formatText(question.title1)}
            </p>
            <p class="card-text text-black font-2 mb-0">
              {formatText(question.description1)}
            </p>
            {question.video && (
              <div className="flex-1 text-muted bd-placeholder-img card-img-top bg-complement rounded mb-3 mt-2  iframe-video">
                <iframe
                  width="100%"
                  height="100%"
                  src={question.video}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            )}
          </div>
        </div>
        <div class="card mb-4 shadow-sm rounded bg-complement option">
          <div class={`card-body bg-white`}>
            <p class="card-text text-second-black font-3 font-weight-bold mb-2">
              {formatText(question.title2)}
            </p>
            <p class="card-text text-black font-2 mb-0">
              {formatText(question.description2)}
            </p>

            <button
              className="mt-3 btn btn-second btn-lg w-100 rounded mt-2"
              onClick={() => setSelectionOpen(true)}
            >
              Abrir câmera
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div class="container py-3">
      <h3 className="text-primary mb-4 font-weight-normal text-center">
        {question.title}
      </h3>
      <div class="card mb-4 shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white`}>
          <p class="card-text text-black font-2 mb-0">
            {formatText(question.question)}
          </p>
          {question.video && (
            <div className="flex-1 text-muted bd-placeholder-img card-img-top bg-complement rounded mb-3 mt-2  iframe-video">
              <iframe
                width="100%"
                height="100%"
                src={question.video}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          )}
          <div class="card-text text-black font-2 mb-0 ">
            {formatText(question.description)}
          </div>

          {selected ? (
            <div className="mb-2 mt-4">
              <b>Você selecionou</b>

              <div className="colors-menu-choice d-flex">
                <div className="flex-1 d-flex align-items-center justify-content-center flex-column">
                  <div className="color mb-1">
                    <img
                      src={`https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/${selected.value}.svg?alt=media`}
                      width="100%"
                    />
                  </div>

                  <div> {selected.text}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-100 text-center">
              <img
                src="/assets/images/mark-example.png"
                className="mt-3 mb-4"
                width="150"
              />
            </div>
          )}

          <button
            className="mt-3 btn btn-second btn-lg w-100 rounded mt-2"
            onClick={() => setSelectionOpen(true)}
          >
            Abrir câmera
          </button>

          <div className="">
            {goBack && (
              <button
                type="button"
                className="btn btn-sm back-button rounded mt-4 px-3 d-inline"
                onClick={goBack}
              >
                Anterior
              </button>
            )}
            {goNext && question.value && (
              <button
                type="button"
                className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right d-inline"
                onClick={goNext}
              >
                Continuar
              </button>
            )}
            {!goNext && question.value && (
              <Link
                type="button"
                className="btn btn-sm back-button btn-primary rounded mt-4 px-3 float-right"
                to={`/quiz/${productId}/finish`}
              >
                Finalizar
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
