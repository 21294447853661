import React, { useEffect } from "react";
import app from "../../base";

export default ({ history }) => {
  useEffect(() => {
    app
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
      })
      .catch((error) => {
        history.push("/");
      });
  }, []);
  return null;
};
