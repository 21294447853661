import React from "react";
import { formatText } from "../../helper/formatText";
import { useProduct, useStartProduct } from "../../hooks/useProducts";
import useCurrentUser from "../../hooks/useCurrentUser";

const Prerequisite = ({ prerequisite }) => {
  const startProduct = useStartProduct(prerequisite.productId);
  return (
    <div class="container py-3">
      <h3 className="text-primary mb-4 font-weight-normal text-center">
        {formatText(prerequisite.title)}
      </h3>
      <div class="card mb-4 shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white`}>
          <div class="card-text mb-2 text-black  mb-0 mb-4">
            {formatText(prerequisite.text)}
          </div>

          {prerequisite.button && (
            <button
              className="btn btn-primary btn-lg w-100 rounded mt-2"
              onClick={startProduct}
            >
              {prerequisite.button}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ({ match: { params } }) => {
  const { idQuiz } = params;
  const currentUser = useCurrentUser();
  const product = useProduct(idQuiz);
  const startProduct = useStartProduct(idQuiz, true);

  const { displayName = "-" } = currentUser;

  const name = displayName.split(" ")[0];
  if (!product) return <p> </p>;
  const { intro, prerequisite, hasPrerequisite } = product;

  if (hasPrerequisite) return <Prerequisite prerequisite={prerequisite} />;

  return (
    <div class="container py-3">
      <h3 className="text-primary mb-4 font-weight-normal text-center">
        {formatText(intro.title, { name })}
      </h3>
      {idQuiz && "analise-de-coloracao-pessoal" && (
        <div class="card mb-4 shadow-sm rounded bg-complement option">
          <div class={`card-body bg-white`}>
            <div class="card-text text-black  mb-0">
              <div className="font-2 mb-4" style={{ height: 18 }}>
                Teremos <b>3 etapas</b>:
              </div>
              <div style={{ color: "#7A7A7A" }}>
                <b style={{ color: "#7A7A7A" }}>1ª</b> - Comece{" "}
                <b style={{ color: "#7A7A7A" }}>agora mesmo </b> sua análise,
                seguindo todas as instruções;
              </div>
              <div style={{ color: "#7A7A7A" }}>
                <b style={{ color: "#7A7A7A" }}>2ª </b>- Eu, May Faggion,{" "}
                <b style={{ color: "#7A7A7A" }}>analiso</b> seus resultados;
              </div>
              <div style={{ color: "#7A7A7A" }}>
                <b style={{ color: "#7A7A7A" }}> 3ª </b>- Agendo com você{" "}
                <b style={{ color: "#7A7A7A" }}>1 hora de consultoria</b> por
                vídeo.
              </div>

              <img
                className="w-100 mt-4"
                src="/assets/invite-consultoria.png"
              />
            </div>
          </div>
        </div>
      )}

      <div class="card mb-4 shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white`}>
          <div class="card-text mb-2 text-black  mb-0 mb-4">
            {formatText(intro.text)}
          </div>
          {intro.video && (
            <div className="flex-1 text-muted bd-placeholder-img card-img-top bg-complement rounded mb-3  iframe-video">
              <iframe
                width="100%"
                height="100%"
                src={intro.video}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          )}

          {intro.button && (
            <button
              className="btn btn-primary btn-lg w-100 rounded mt-2"
              onClick={startProduct}
            >
              {intro.button}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
