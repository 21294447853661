import React, { useRef, useEffect, useState } from "react";

export default ({ colors, onUpdate }) => {
  const canvas = useRef(null);

  const width = 100;
  const height = 100;
  const [x, setX] = useState(width / 2 - 7);
  const [y, setY] = useState(height / 2 + 15);
  const [isEditing, setEditing] = useState(false);
  const drawMesh = (colors) => {
    const ctx = canvas.current.getContext("2d");
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
    canvas.current.height = height;
    canvas.current.width = width;

    const center = [height / 2, width / 2];

    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, width);
    ctx.lineTo(height, width);
    ctx.lineTo(height, 0);
    ctx.closePath();
    ctx.fillStyle = "#FFFFFF";
    ctx.fill();

    const drwaTriagle = (color, start, end) => {
      ctx.beginPath();
      ctx.moveTo(...start);
      ctx.lineTo(...center);
      ctx.lineTo(...end);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    };
    const add = 20;
    const [c1, c2, c3, c4] = colors;
    drwaTriagle(c1, [add, add], [add, height - add]);
    drwaTriagle(c2, [add, add], [width - add, add]);
    drwaTriagle(c3, [width - add, add], [width - add, height - add]);
    drwaTriagle(c4, [add, height - add], [width - add, height - add]);
  };

  const drawLight = () => {
    const ctx = canvas.current.getContext("2d");
    ctx.beginPath();
    ctx.arc(x, y, 4, 0, 2 * Math.PI, false);
    ctx.fillStyle = "white";
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#003300";
    ctx.stroke();
    ctx.fill();
  };
  const getMousePos = (evt) => {
    var rect = canvas.current.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top,
    };
  };
  const updateXY = (e) => {
    if (!isEditing) return;
    const { x, y } = getMousePos(e);
    setX(x);
    setY(y);

    // conversion to update
    let xConverted = x - width / 2;
    let yConverted = y - width / 2;
    onUpdate(xConverted, yConverted);
  };

  useEffect(() => {
    if (colors) drawMesh(colors);
    drawLight();
  }, [canvas]);
  useEffect(() => {
    // conversion to update
    let xConverted = x - width / 2;
    let yConverted = y - width / 2;
    onUpdate(xConverted, yConverted);
  }, []);

  useEffect(() => {
    drawMesh(colors);
    drawLight();
  }, [colors, x, y]);

  return (
    <canvas
      onMouseMove={updateXY}
      onMouseDown={() => setEditing(true)}
      onMouseUp={() => setEditing(false)}
      ref={canvas}
      style={{ height: 100, width: 100 }}
    ></canvas>
  );
};
