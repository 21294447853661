import React, { useState, useRef } from "react";
import { useSiteConfig, useSiteConfigReload } from "../../hooks/useSiteConfig";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { db } from "../../base";
import TextareaAutosize from "react-textarea-autosize";
import useCurrentUser from "../../hooks/useCurrentUser";
import { formatText } from "../../helper/formatText";

const Editable = ({ className, close, value, field }) => {
  const div = useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const [loading, setIsLoading] = useState(false);
  const reload = useSiteConfigReload();
  useOutsideAlerter(div, close(false));

  const handleSave = () => {
    setIsLoading(true);
    db.collection("apps")
      .doc("mf")
      .update({
        [field]: inputValue,
      })
      .then((c) => {
        reload();
        setIsLoading(false);
        close(false)();
      });
  };

  return (
    <div
      ref={div}
      className="editable-text"
      style={{
        opacity: loading ? 0.5 : 1,
      }}
    >
      <TextareaAutosize
        className={className}
        value={inputValue}
        rows={1}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <div className="toll-box">
        <button className="btn btn-success btn-sm" onClick={handleSave}>
          Salvar
        </button>
        <button className="btn btn-danger btn-sm" onClick={close(false)}>
          Fechar
        </button>
      </div>
    </div>
  );
};

export default ({ field, className = "", args = {} }) => {
  const siteCofig = useSiteConfig();
  const currentUser = useCurrentUser();
  const value = siteCofig[field] || "-";

  const isAdmin = currentUser && currentUser.isAdmin;
  const [editing, setIsEditing] = useState(false);
  const handleEdit = (value) => () => setIsEditing(value);

  if (!editing || !isAdmin)
    return (
      <div
        className={className + (isAdmin ? " editable-value" : "")}
        onClick={handleEdit(true)}
      >
        {formatText(value, args)}
      </div>
    );

  return (
    <Editable
      className={className}
      field={field}
      value={value}
      close={handleEdit}
    />
  );
};
