import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatText } from "../../helper/formatText";

import Carousel from "react-instagram-carousel";
import Story from "../story/Story";
import StoryWithMenu from "../story/StoryWithMenu";
import Pallet from "../pallet/Pallet";

export default ({ finish, menu, hideButton = false, hideTitle = false }) => {
  const [gender, setGender] = useState("feminino");
  const [showBtn, setShowBtn] = useState(false);

  const changeGender = (value) => () => setGender(value);

  useEffect(() => {
    const handleScroll = (event) => {
      setShowBtn(window.pageYOffset >= 64);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const style_combination = {
    basico: "neutros + tons alegres",
    classico: "cinzas, preto, petróleo, uva, azuis e verdes profundos",
    romantico: "tons claros da cartela em combinações análogas",
    sexy: "pink, preto, violetas, branco, esmeralda",
    dramatico: "tons escuros e intensos da cartela e forte contraste",
    criativo: "combinações incomuns usando as cores da cartela",
  };
  const style_name = {
    basico: "Básico",
    classico: "Clássico",
    romantico: "Romântico",
    sexy: "Sexy",
    dramatico: "Dramático",
    criativo: "Criativo",
  };

  let params = {};

  if (finish.resultPrerequisite) {
    const resultPrerequisite = Array.isArray(finish.resultPrerequisite)
      ? finish.resultPrerequisite[0]
      : finish.resultPrerequisite;
    params.style_combination = style_combination[resultPrerequisite];
    params.style_name = style_name[resultPrerequisite];
  }

  return (
    <div>
      {showBtn && finish.button && !hideButton && (
        <Link
          to={finish.buttonTo}
          className="btn-secondary btn-lg w-100  mt-2 facein-element"
          style={{
            position: "fixed",
            top: 64,
            left: 0,
            borderRadius: "0px 0 8px 8px",
            background: "#5a6268",
            zIndex: 999,
          }}
        >
          {finish.button}
        </Link>
      )}
      {!hideTitle && (
        <h3 className="text-primary mb-4 font-weight-normal text-center">
          {finish.title}
        </h3>
      )}
      {menu && menu}
      {/* pallets={finish.pallets}  */}
      {finish.pallets && finish.pallets.length > 0 && (
        <Pallet pallets={finish.pallets} />
      )}
      <div class="card mb-4 mt-3 shadow-sm rounded bg-complement option">
        <div class={`card-body bg-white`}>
          {finish.video && (
            <div className="flex-1 text-muted bd-placeholder-img card-img-top bg-complement rounded mb-3 iframe-video">
              <iframe
                width="100%"
                height="100%"
                src={finish.video}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
          )}
          <p className="mb-2 font-weight-bold text-black h5">
            {finish.resultTitle}
          </p>
          {/* {finish.image && (
            <p className="mb-2 text-center">
              <img src={finish.image} style={{ maxWidth: 480 }} width="100%" />
            </p>
          )} */}

          <div class="card-text mb-2 text-second-black font-2 mb-0 result-text mb-4">
            {formatText(finish.text, params)}
          </div>

          {finish.carousel && finish.carousel.length > 0 && (
            <StoryWithMenu values={finish.carousel} />
          )}
        </div>
      </div>
      {finish.callToAction && (
        <div class="card mb-4 shadow-sm rounded bg-complement option">
          <div class={`card-body bg-white`}>
            <p className="text-black">{formatText(finish.callToAction.text)}</p>
            <p className="text-second-black" style={{ fontSize: 14 }}>
              {formatText(finish.callToAction.subtext)}
            </p>

            <Link
              to={finish.callToAction.link}
              className="btn btn-second btn-lg w-100 rounded mt-2"
            >
              {formatText(finish.callToAction.button)}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
