import React, { useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import { Link, useHistory, useLocation } from "react-router-dom";
import app, { db } from "../../base.js";
export default (props) => {
  const [opened, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const handleOpen = (value) => () => setOpen(value);
  const logout = () => {
    history.push("/");
    handleOpen(false)();
    app.auth().signOut();
  };

  const active = (link) => {
    if (pathname == link) return "active";
    return "";
  };

  const currentUser = useCurrentUser();
  return (
    <header>
      <div className="header">
        <div class="navbar navbar-dark shadow bg-white">
          <div class="container d-flex justify-content-between">
            <Link to="/" class="navbar-brand d-flex align-items-center p-0">
              <img src="/assets/images/logo-menu.svg" height="56px" />
            </Link>
            {!currentUser ? (
              <Link to={"/login"}>
                <button
                  class={"navbar-toggler btn btn-login " + active("/login")}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarHeader"
                  aria-controls="navbarHeader"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  Login
                </button>
              </Link>
            ) : (
              <div className="burger-icon" onClick={() => setOpen(!opened)}>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div
            class={"bg-white-second shadow menu " + (opened ? "expanded" : "")}
            id="navbarHeader"
          >
            <Link
              className={"item " + active("/")}
              to="/"
              onClick={handleOpen(false)}
            >
              Início
            </Link>
            <Link
              className={"item " + active("/perfil")}
              to="/perfil"
              onClick={handleOpen(false)}
            >
              Perfil
            </Link>
            <Link
              className={"item " + active("/me-ajuda")}
              to="/me-ajuda"
              onClick={handleOpen(false)}
            >
              Me Ajuda
            </Link>
            <div
              className={"item text-complement d-flex align-items-center"}
              onClick={logout}
            >
              <img src="/assets/images/logout.svg" className="mr-2" />
              Sair
            </div>
          </div>
          <div
            onClick={handleOpen(false)}
            className={"overlay " + (opened ? "expanded" : "")}
          ></div>
        </div>
      </div>
    </header>
  );
};
