import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import QuizFinish from "../../Quiz/QuizFinish";
import { db } from "../../../../base";
import QuizFinish from "../../../../components/quiz/QuizFinish";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
function useResult({ match = {} }) {
  const { productId, resultQuiz } = match.params || {};
  const [result, setResut] = useState();

  useState(() => {
    db.collection("products")
      .doc(productId)
      .collection("results")
      .doc(resultQuiz)
      .get()
      .then((i) => i.data())
      .then((r) => setResut(r));
  }, []);
  return result;
}

export default (props) => {
  const result = useResult(props);
  const [resultPrerequisite, setResultPrerequisite] = useState();
  const { match = {} } = props;
  const { productId, resultQuiz } = match.params || {};
  const history = useHistory();
  const itemRef = useRef(null);

  useEffect(() => {
    document.body.className += " no-scroll ";

    return () => {
      document.body.className = document.body.className.replaceAll(
        " no-scroll ",
        ""
      );
    };
  }, []);
  const close = () => {
    history.push(`/adm/${productId}`);
  };

  const objToOption = (obj) => {
    return Object.keys(obj).map((key) => (
      <option key={key} value={key}>
        {obj[key]}
      </option>
    ));
  };

  useOutsideAlerter(itemRef, close);
  if (!result) return null;
  return (
    <div class="modal fade show" style={{ display: "block" }}>
      <div class="modal-dialog" role="document" ref={itemRef}>
        <div
          class="modal-content"
          style={{
            overflow: "auto",
            maxHeight: "90vh",
          }}
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {resultQuiz}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <b>Variávies</b>
            <div className="form-group">
              <label>Combinação</label>
              <select
                className="form-control"
                onChange={(e) => setResultPrerequisite(e.target.value)}
              >
                <option></option>
                {objToOption({
                  basico: "Básico",
                  classico: "Clássico",
                  romantico: "Romantico",
                  sexy: "Sexy",
                  dramatico: "Dramático",
                  criativo: "Criativo",
                })}
              </select>
            </div>
          </div>
          <div
            style={{
              background: "black",
              width: "100%",
              height: 1,
            }}
          ></div>
          <div class="modal-body">
            <QuizFinish
              finish={{
                ...result,
                resultPrerequisite,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
