import React, { useEffect, useRef, useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import "./story.scss";
export default ({ itens = [] }) => {
  const [_itens, setItens] = useState([]);
  const [height, setHeight] = useState(400);
  const container = useRef(null);
  //   height: 466px;
  const [currentTime, setCurrentTime] = useState(0);
  const seconds = 5;
  const timeByPage = seconds * 1000;
  const tick = seconds * 10;
  const maxtime = _itens.length * timeByPage;

  const calcPercent = (index) => {
    const proportion = currentTime / timeByPage;
    return Math.max(
      0,
      Math.min(100, 100 - Math.floor((1 + index - proportion) * 100))
    );
  };

  useEffect(() => {
    setCurrentTime(0);
    setItens(itens);
  }, [itens]);
  useEffect(() => {
    const width = container.current.offsetWidth;
    // // 300 - 466
    // // width - height
    // // height =
    // console.log();
    setHeight((466 * width) / 300);
  }, [container.current]);

  const goNext = (index) => {
    if (index + 1 === index.length) {
      setCurrentTime(0);
    } else {
      setCurrentTime(timeByPage * (index + 1));
    }
  };
  const goBack = (index) => {
    console.log("Go back", index);
    if (index - 1 < 0) {
      setCurrentTime(0);
    } else {
      setCurrentTime(timeByPage * (index - 1));
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTime((time) => (maxtime == time ? 0 : time + tick));
    }, tick);
    return () => clearInterval(interval);
  }, [_itens]);

  const index = Math.floor(currentTime / timeByPage);
  const { url, description } =
    (index == _itens.length ? _itens[index - 1] : _itens[index]) || {};
  return (
    <div className="d-flex flex-column align-items-center">
      <div
        className="story"
        ref={container}
        style={{
          height,
          backgroundImage: `url('${url}')`,
        }}
      >
        <div className="story-content">
          {/* <img src={url} /> */}
          <div className="controls">
            <div className="preview" onClick={() => goBack(index)}></div>
            <div className="preview" onClick={() => goNext(index)}></div>
          </div>
          <div className="timer">
            {_itens.map((i, index) => (
              <div className="timer-item" key={index}>
                <div
                  className="timer-item-complete"
                  style={{
                    width: `${calcPercent(index)}%`,
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="caption">{description}</div>
    </div>
  );
};
