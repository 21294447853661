import React, { useEffect, useState } from "react";
import "./admin.scss";
import { db } from "../../../base";
import { Link } from "react-router-dom";

function useQuestions(productId) {
  const [results, setResults] = useState();

  useEffect(() => {
    db.collection("products")
      .doc(productId)
      .collection("questions")
      .onSnapshot((data) => {
        setResults(
          data.docs.map((d) => ({
            data: d.data(),
            id: d.id,
          }))
        );
      });
    // .get()
    // .then((p) => console.log(p.docs.map((d) => d.get())));
  }, []);

  return results;
}

const EditResult = ({ name, productId }) => {
  const [showBody, setShowBody] = useState(false);

  return (
    <div className="card  rounded mb-4">
      <div class="card-header" onClick={() => setShowBody((v) => !v)}>
        <h5 class="mb-0">{name}</h5>
      </div>

      <div>
        <div
          class="card-body"
          style={{
            display: !showBody ? "none" : "block",
          }}
        >
          <Link
            to={`/adm/${productId}/questao/${name}`}
            className="btn btn-primary w-100 mb-4"
          >
            Ver resultado
          </Link>
          {/* <Link
            to={`/adm/${productId}/questao/${name}/editar`}
            className="btn btn-secondary w-100 mb-4"
          >
            Editar resultado
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default ({ idQuiz }) => {
  const quesitons = useQuestions(idQuiz);

  const register = () => {};
  const renderItem = (item, parentKey) => {
    // console.log(item);
    if (item.type == "array")
      return (
        <div className="form-group">
          <b>{item.key}</b>
          <div className="pl-2">
            {item.itens.map((i) => renderItem(i, item.key))}
          </div>
          <hr />
        </div>
      );
    return (
      <div className="form-group">
        <label>{item.key}</label>
        <input
          className="form-control"
          {...register(parentKey ? `${parentKey}.${item.key}` : item.key)}
        />
      </div>
    );
  };

  const itens = (quesitons || []).map((r) => ({
    name: r.id,
    // itens: Object.keys(r.data).map((k) => itemToArr(k, r.data[k])),
    type: "array",
  }));

  // console.log(itens);

  return (
    <div>
      <h5 class="card-title mb-8">Perguntas</h5>
      {/* {JSON.stringify(result)} */}
      {/* {itens.map((item) => renderItem(item))} */}
      {(quesitons || []).map((q) => (
        <EditResult key={q.id} name={q.id} productId={idQuiz} />
      ))}
    </div>
  );
};
