import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./admin.scss";
import QuestionView from "./components/QuestionView";
import ResultEdit from "./components/ResultEdit";
import ResultView from "./components/ResultView";
import ProdutEdit from "./ProdutEdit";
import QuetionsEdit from "./QuetionsEdit";
import ResultsEdit from "./ResultsEdit";
export default ({ match }) => {
  const { productId: idQuiz } = match.params;
  return (
    <>
      <Router>
        <Route
          path="/adm/:productId"
          render={() => (
            <div className="container pt-4">
              <div className="row">
                <div className="col-4">
                  <b>Produto:</b>{" "}
                  <select className="mt-1 form-control">
                    <option>Produto 1</option>
                  </select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <ProdutEdit idQuiz={idQuiz} />
                </div>
                <div className="col-4">
                  <QuetionsEdit idQuiz={idQuiz} />
                </div>
                <div className="col-4">
                  <ResultsEdit idQuiz={idQuiz} />
                </div>
              </div>
            </div>
          )}
        />
        <Route
          path="/adm/:productId/resultado/:resultQuiz"
          component={ResultView}
          exact
        />
        <Route
          path="/adm/:productId/resultado/:resultQuiz/editar"
          component={ResultEdit}
          exact
        />
        <Route
          path="/adm/:productId/questao/:questionid"
          component={QuestionView}
          exact
        />
      </Router>
    </>
  );
};
