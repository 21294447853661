import React from "react";
import Value from "../app-config/Value";

export default ({ id, img }) => {
  return (
    <div>
      <div className="mb-3 d-flex align-items-center justify-content-center">
        <img src="/assets/images/quotation_marks.svg" />
      </div>
      <p className="text-center font-2 mb-2 scroll-light depoimento-texto">
        <Value
          field={"testimony-description-" + id}
          class="text-center font-2 mb-2"
        />
      </p>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="rounded-circle user-image bg-complement mr-2 depoimentos"
          style={{
            background: `url(${img})`,
          }}
        ></div>
        <div className="text-second font-2 font-weight-bold">
          <Value
            field={"testimony-name-" + id}
            class="text-second font-2 font-weight-bold"
          />
        </div>
      </div>
    </div>
  );
};
