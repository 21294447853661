import React, { useState } from "react";
import ProfileEdit from "../../components/profile/ProfileEdit";
import ProfileProduct from "../../components/profile/ProfileProduct";
import Value from "../../components/app-config/Value";

export default () => {
  const [tab, setTab] = useState(0);
  const handleSetTab = (tab) => () => {
    setTab(tab);
  };
  return (
    <div class="container py-3">
      <div class="card mb-4 shadow-sm rounded nt p-0" mb-4>
        <div class={` bg-white p-0 custom-menu`}>
          <div
            onClick={handleSetTab(0)}
            className={"item" + (tab == 0 ? " active" : "")}
          >
            <Value field="profile-tab-1" />
          </div>
          <div
            onClick={handleSetTab(1)}
            className={"item" + (tab == 1 ? " active" : "")}
          >
            <Value field="profile-tab-2" />
          </div>
          <div
            onClick={handleSetTab(2)}
            className={"item" + (tab == 2 ? " active" : "")}
          >
            <Value field="profile-tab-3" />
          </div>
        </div>
      </div>
      {tab == 0 && (
        <ProfileEdit title={<h5 class="card-title mb-3">Dados Pessoais</h5>} />
      )}
      {tab == 1 && (
        <ProfileProduct
          hideButton
          name="Definição de estilo"
          productId={"analise-e-definicao-de-estilo"}
        />
      )}
      {tab == 2 && (
        <ProfileProduct
          name="Coloração Pessoal"
          hideButton
          productId={"analise-de-coloracao-pessoal"}
        />
      )}
    </div>
  );
};
