import React from "react";

export function formatText(value, params) {
  if (!value || !value.split) return value;
  let valueReplaced = value;
  if (params) {
    Object.keys(params || {}).forEach(
      (key) =>
        (valueReplaced = valueReplaced.replace(`{{${key}}}`, params[key]))
    );
  }

  const valueFormatted = valueReplaced.split("\\n");

  return (
    <>
      {valueFormatted.map((value, i) => (
        <div key={i}>
          {value
            .split("*")
            .map((v, index) =>
              index % 2 == 0 ? (
                <span key={index}>{v}</span>
              ) : (
                <b key={index}>{v}</b>
              )
            )}
          {i != valueFormatted.length - 1 && <br />}
        </div>
      ))}
    </>
  );
}
