import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import QuizFinish from "../../Quiz/QuizFinish";
import "../admin.scss";
import QuizFinish from "../../../../components/quiz/QuizFinish";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import CarrosselEdit from "./CarrosselEdit";
import { formatText } from "../../../../helper/formatText";
import ProdutEdit from "../ProdutEdit";
import PreviewText from "./PreviewText";
import PalletsEdit from "./PalletsEdit";
import { db } from "../../../../base";
function useResult({ match = {} }) {
  const { productId, resultQuiz } = match.params || {};
  const [result, setResut] = useState();

  useState(() => {
    db.collection("products")
      .doc(productId)
      .collection("results")
      .doc(resultQuiz)
      .get()
      .then((i) => i.data())
      .then((r) => setResut(r));
  }, []);
  return result;
}

function useSaveResult(productId, resultQuiz) {
  return (data) => {
    db.collection("products")
      .doc(productId)
      .collection("results")
      .doc(resultQuiz)
      .update(data)
      .then(() => alert("Salvo"))
      .catch(() => alert("Erro"));
  };
}
export default (props) => {
  const result = useResult(props);
  const { match = {} } = props;
  const { productId, resultQuiz } = match.params || {};
  const [lastChange, setLastChange] = useState("");
  const [productToEdit, setProductToEdit] = useState();
  const saveResult = useSaveResult(productId, resultQuiz);
  const history = useHistory();
  const itemRef = useRef(null);

  useEffect(() => {
    if (result) {
      setLastChange(JSON.stringify(result));
      setProductToEdit(JSON.parse(JSON.stringify(result)));
    }
  }, [result]);

  useEffect(() => {
    document.body.className += " no-scroll ";

    return () => {
      document.body.className = document.body.className.replaceAll(
        " no-scroll ",
        ""
      );
    };
  }, []);

  const register = (name) => {
    return {
      value: productToEdit ? productToEdit[name] || "" : "",
      onChange: (e) => {
        const value = e && e.target && e.target.value ? e.target.value : e;
        setProductToEdit((item) => ({
          ...item,
          [name]: value,
        }));
      },
    };
  };

  const close = () => {
    history.push(`/adm/${productId}`);
  };

  const save = () => {
    saveResult(productToEdit);
    setLastChange(JSON.stringify(productToEdit));
  };
  const fullObj = {
    title: "string",
    description: "textarea",
    carousel: [
      // {
      //   url: 'url',
      //   type: 'Feminino',
      //   description: ''
      // }
    ],
    pallets: [
      // {
      //   type: 'Roupa',
      //   description: ''
      // colors: [
      //   {
      // mainColor: "#AAA",
      // url: "",
      //   }
      // ]
      // },
    ],
  };

  useOutsideAlerter(itemRef, close);
  if (!result || !productToEdit) return null;
  return (
    <div class="modal fade show" style={{ display: "block" }}>
      <div class="modal-dialog modal-xl" role="document" ref={itemRef}>
        <div
          class="modal-content "
          style={{
            overflow: "auto",
            maxHeight: "90vh",
          }}
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Editar: {resultQuiz}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(productToEdit, null, 2)}</pre> */}
            <div className="form-group form-group-divisor">
              <label>Título</label>
              <input className="form-control" {...register("title")} />
            </div>
            <div className="form-group form-group-divisor">
              <label>Título do Resultado </label>
              <input className="form-control" {...register("resultTitle")} />
            </div>
            <div className="form-group form-group-divisor">
              <label>Descrição</label>
              <div className="row">
                <div className="col-6">
                  <textarea
                    rows={8}
                    className="form-control"
                    {...register("text")}
                  />
                </div>
                <div className="col-6">
                  <PreviewText
                    text={productToEdit ? productToEdit.text || "" : ""}
                  />
                </div>
              </div>
            </div>
            <div className="form-group form-group-divisor">
              <label>Carrossel</label>
              <CarrosselEdit {...register("carousel")} />
            </div>
            <div className="form-group form-group-divisor">
              <label>Palleta</label>
              <PalletsEdit {...register("pallets")} />
            </div>
          </div>

          <button
            disabled={lastChange == JSON.stringify(productToEdit)}
            onClick={save}
            className="btn btn-primary mt-4"
          >
            Salvar informações
          </button>
        </div>
      </div>
    </div>
  );
};
