import React from "react";
import Value from "../app-config/Value";
import { Link } from "react-router-dom";

export default ({ buttonText, description, to, type = "primary" }) => {
  return (
    <div class="card mb-4 shadow-sm rounded bg-complement">
      <div class={`card-body bg-${type}-light`}>
        <p class="card-text text-center mb-2 text-black p-3 font-3 mb-0">
          <Value field={description} />
        </p>

        <Link to={to} className="btn btn-primary btn-lg w-100 rounded">
          <Value field={buttonText} />
        </Link>
      </div>
    </div>
  );
};
