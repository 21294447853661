import React, { useRef, useEffect, useState, useContext } from "react";
import { useHelpModal } from "../../../hooks/useHelpModal";
import ColorCompareHeaderMenu from "./ColorCompareHeaderMenu";
import { ColorQuestionContext } from "./ColorQuestionContext";
import ColorsOptions from "./ColorsOptions";
import CameraFaceMask from "./CameraFaceMask";
import CameraFaceMask2 from "./CameraFaceMask copy";
// import ColorAnalyze from "../../../pages/Simulation/ColorAnalyze";
const AnimationInfo = () => {
  const [animating, setAnimatin] = useState(false);
  const [videosAutoPlays, setVideosAutoPlays] = useState([]);
  const { currentCompare, options, video } = useContext(ColorQuestionContext);

  const showVideo = useHelpModal("video", () => false, (video || {}).title, [
    video,
  ]);

  useEffect(() => {
    if (video && !videosAutoPlays.includes(currentCompare)) showVideo();
    setVideosAutoPlays((videosAutoPlays) =>
      videosAutoPlays.concat([currentCompare])
    );
  }, [video]);

  useEffect(() => {
    setAnimatin(true);
    setTimeout(() => {
      setAnimatin(false);
    }, 2000);
  }, [currentCompare]);

  if (currentCompare == 0 || currentCompare == options.length - 1) return null;
  if (!animating) return null;
  return (
    <div className="count-comparation">
      <div className="box-count">{currentCompare + 1}ª comparação!</div>
    </div>
  );
};

export default () => {
  const {
    currentCompareOptions,
    currentCompareSelected,
    results,
    changeCurrentCompareSelected,
    nextCurrentcompare,
  } = useContext(ColorQuestionContext);

  const tryNewFeature = false && localStorage.try;
  return (
    <div
      style={{
        height: "calc(100vh - 72px)",
        overflow: "hidden",
      }}
      className="d-flex flex-column"
    >
      <AnimationInfo />
      <div
        className="d-flex flex-1  justify-content-center position-relative "
        style={{ overflow: "hidden" }}
      >
        <ColorCompareHeaderMenu />
        {/* {tryNewFeature && <ColorAnalyze {...currentCompareSelected} />} */}

        <CameraFaceMask
          options={currentCompareOptions}
          currentCompareSelected={currentCompareSelected}
          changeCurrentCompareSelected={changeCurrentCompareSelected}
        />
        {/* {!tryNewFeature && <CameraFaceMask2 options={currentCompareOptions} />} */}

        {/*  */}
        <div
          className=" position-absolute w-100 d-flex justify-content-end px-4"
          style={{ bottom: 10, zIndex: 10, zIndex: 1001 }}
        >
          <div className="btn btn-light btn-color" onClick={nextCurrentcompare}>
            Avançar
          </div>
        </div>
      </div>
      {/* <ColorsOptions /> */}
    </div>
  );
};
