import { weightsLoaderFactory } from "@tensorflow/tfjs-core/dist/io/io";
import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const clothWidth = 200;
const clothHeight = 200;
const margin = 0.05;
const clock = new THREE.Clock();

export default class Scene extends React.Component {
  componentDidMount() {
    console.log(window.Ammo);
    if (window.Ammo)
      window.Ammo().then((AmmoLib) => {
        this.needUpdatePhysics = true;
        // setTimeout(() => (this.needUpdatePhysics = false), 5000);
        this.Ammo_ = AmmoLib;
        this.init();
        this.animate = this.animate.bind(this);
        this.props.registerAnimate(this.animate);
        // setTimeout(() => (this.needUpdatePhysics = false), 3 * 1000);
      });
  }

  init() {
    this.rigidBodies = [];
    this.initGraphics();
    this.initPhysics();
    this.createObjects();
    this.props.onStart();
  }

  updateCanvas(canvas) {
    const texture = new THREE.CanvasTexture(canvas);
    this.cloth.material = new THREE.MeshLambertMaterial({
      //   emissive: new THREE.Color(0xffffff),  {
      //   shininess: 80,
      //   color: 0xffaaff,
      //   specular: 0xffffff,
      //   side: THREE.DoubleSide,
      map: texture,
    });
  }

  updateCanvasFace(canvas) {
    const texture = new THREE.CanvasTexture(canvas);
    this.face.material = new THREE.MeshBasicMaterial({
      transparent: true,
      map: texture,
    });
  }

  updateLight(x, y) {
    const xCalc = (x * 100) / 30;
    const yCalc = (y * 100) / 30;
    // 100 - 30
    // ?  - x

    this.light.position.set(xCalc, 100, yCalc);
    this.lightObj.position.set(0, 50, 0);
  }

  initGraphics() {
    const w = window.innerWidth;
    const h = this.container.offsetHeight || window.innerHeight;
    this.camera = new THREE.PerspectiveCamera(60, w / h, 0.2, 2000);

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xbfd1e5);

    this.camera.position.set(0, clothHeight, 0);

    this.renderer = new THREE.WebGLRenderer();
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(w, h);
    // this.renderer.shadowMap.enabled = true;
    this.container.appendChild(this.renderer.domElement);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target.set(0, 2, 0);
    this.controls.update();

    this.textureLoader = new THREE.TextureLoader();

    let ambientLight = new THREE.AmbientLight(0x404040);
    // this.scene.add(ambientLight);
    var lightObj = new THREE.Mesh(
      new THREE.BoxBufferGeometry(10, 10, 10, 1, 1, 1),
      new THREE.MeshLambertMaterial({
        color: "#FF0000",
        side: THREE.DoubleSide, // important!
      })
    );
    var lightObj2 = new THREE.Mesh(
      new THREE.BoxBufferGeometry(3, 3, 3, 1, 1, 1),
      new THREE.MeshLambertMaterial({
        color: "#FFFF00",
        side: THREE.DoubleSide, // important!
      })
    );
    // this.scene.add(lightObj);
    // this.scene.add(lightObj2);
    lightObj2.position.set(0, 70, 0);
    let light = new THREE.DirectionalLight(0xffffff, 1);
    this.light = light;
    this.lightObj = lightObj;
    light.position.set(0, 15, 0);
    light.lookAt(0, 0, 0);
    light.receiveShadow = true;
    light.castShadow = true;
    lightObj.receiveShadow = true;
    lightObj.castShadow = true;

    lightObj2.receiveShadow = true;
    lightObj2.castShadow = true;
    // let d = 10;
    // light.shadow.camera.left = -d;
    // light.shadow.camera.right = d;
    // light.shadow.camera.top = d;
    // light.shadow.camera.bottom = -d;
    light.shadow.camera.near = 1;
    light.shadow.camera.far = 4000;
    light.shadow.camera.fov = 300;
    // light.shadow.camera.near = 0.01;
    light.shadow.camera.far = 500;

    light.shadow.mapSize.x = 1024 * 2;
    light.shadow.mapSize.y = 1024 * 2;

    light.shadow.bias = -0.003;
    this.scene.add(light);

    // window.addEventListener("resize", this.onWindowResize, false);
    this.renderer.setPixelRatio(window.devicePixelRatio);
  }

  initPhysics() {
    let gravityConstant = -9.8 * 10;
    let collisionConfiguration = new this.Ammo_.btSoftBodyRigidBodyCollisionConfiguration();
    let dispatcher = new this.Ammo_.btCollisionDispatcher(
      collisionConfiguration
    );
    let broadphase = new this.Ammo_.btDbvtBroadphase();
    let solver = new this.Ammo_.btSequentialImpulseConstraintSolver();
    let softBodySolver = new this.Ammo_.btDefaultSoftBodySolver();
    this.physicsWorld = new this.Ammo_.btSoftRigidDynamicsWorld(
      dispatcher,
      broadphase,
      solver,
      collisionConfiguration,
      softBodySolver
    );
    this.physicsWorld.setGravity(
      new this.Ammo_.btVector3(0, gravityConstant, 0)
    );
    this.physicsWorld
      .getWorldInfo()
      .set_m_gravity(new this.Ammo_.btVector3(0, gravityConstant, 0));

    this.transformAux1 = new this.Ammo_.btTransform();
  }

  createObjects() {
    let pos = new THREE.Vector3();
    let quat = new THREE.Quaternion();

    let clothNumSegmentsZ = 30;
    let clothNumSegmentsY = 30;
    let clothPos = new THREE.Vector3(-clothWidth / 2, 3, clothHeight / 2);
    let clothGeometry = new THREE.PlaneBufferGeometry(
      clothWidth,
      clothHeight,
      clothNumSegmentsZ,
      clothNumSegmentsY
    );
    clothGeometry.rotateY(Math.PI * 0.5);
    clothGeometry.translate(
      clothPos.x,
      clothPos.y + clothHeight * 0.5,
      clothPos.z - clothWidth * 0.5
    );

    let clothMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
    });

    this.clothMaterial = clothMaterial;

    clothGeometry.computeFaceNormals();
    clothGeometry.computeVertexNormals();
    clothGeometry.computeBoundingBox();

    const cloth = new THREE.Mesh(clothGeometry, clothMaterial);
    this.cloth = cloth;
    cloth.castShadow = true;
    cloth.receiveShadow = true;
    this.scene.add(cloth);
    // Cloth physic object
    let softBodyHelpers = new this.Ammo_.btSoftBodyHelpers();
    // const heightCloth = 2;
    let heightCloth = 10;
    let pylonHeight = 10;
    let clothCorner00 = new this.Ammo_.btVector3(
      clothPos.x,
      heightCloth,
      clothPos.z
    );
    let clothCorner01 = new this.Ammo_.btVector3(
      clothPos.x,
      heightCloth,
      clothPos.z - clothWidth
    );
    let clothCorner10 = new this.Ammo_.btVector3(
      clothPos.x + clothHeight,
      heightCloth,
      clothPos.z
    );
    let clothCorner11 = new this.Ammo_.btVector3(
      clothPos.x + clothHeight,
      heightCloth,
      clothPos.z - clothWidth
    );
    let clothSoftBody = softBodyHelpers.CreatePatch(
      this.physicsWorld.getWorldInfo(),
      clothCorner00,
      clothCorner01,
      clothCorner10,
      clothCorner11,
      clothNumSegmentsZ + 1,
      clothNumSegmentsY + 1,
      0,
      true
    );
    let sbConfig = clothSoftBody.get_m_cfg();
    sbConfig.set_viterations(10);
    sbConfig.set_piterations(30);

    clothSoftBody.setTotalMass(10, false);
    this.Ammo_.castObject(clothSoftBody, this.Ammo_.btCollisionObject)
      .getCollisionShape()
      .setMargin(margin * 3);
    this.physicsWorld.addSoftBody(clothSoftBody, 1, -1);
    cloth.userData.physicsBody = clothSoftBody;
    // Disable deactivation
    clothSoftBody.setActivationState(4);

    // The base
    let armMass = 2;
    let armLength = +clothWidth;
    let baseMaterial2 = new THREE.MeshPhongMaterial({ color: 0xff0000 });
    quat.set(0, 0, 0, 1);
    pos.set(clothPos.x, 0.5 * pylonHeight, clothPos.z - armLength);

    pos.set(clothPos.x, pylonHeight + 0.2, clothPos.z - 0.5 * armLength);
    const arm = this.createParalellepiped(
      clothHeight / (5 * 10),
      clothHeight / (5 * 10),
      armLength + clothHeight / (5 * 10),
      armMass * 0,
      pos,
      quat,
      baseMaterial2
    );
    arm.castShadow = true;
    arm.receiveShadow = true;
    pos.set(
      clothPos.x + clothHeight,
      pylonHeight + 0.2,
      clothPos.z - 0.5 * armLength
    );

    let arm2 = this.createParalellepiped(
      clothHeight / (5 * 10),
      clothHeight / (5 * 10),
      armLength + clothHeight / (5 * 10),
      armMass * 0,
      pos,
      quat,
      baseMaterial2
    );
    arm2.castShadow = true;
    arm2.receiveShadow = true;

    // square

    pos.set(0, -2 + clothWidth / 5 / 2, 0);
    this.face = this.createParalellepiped(
      clothWidth / 2.75,
      0.1,
      clothWidth / 2.75,
      0,
      pos,
      quat,
      this.maskMaterial,
      true
    );

    pos.set(0, -2, 0);
    const square = this.createParalellepiped(
      clothWidth / 5,
      clothWidth / 5,
      clothWidth / 5,
      0,
      pos,
      quat,
      new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
      }),
      false
    );
    square.castShadow = true;
    square.receiveShadow = true;
    square.userData.physicsBody.setCollisionFlags(2);

    // Glue the cloth to the arm
    let influence = 0.5;

    clothSoftBody.appendAnchor(0, arm.userData.physicsBody, false, influence);
    clothSoftBody.appendAnchor(
      clothNumSegmentsZ,
      arm.userData.physicsBody,
      false,
      influence
    );
    clothSoftBody.appendAnchor(
      clothNumSegmentsZ * (clothNumSegmentsZ + 1),
      arm2.userData.physicsBody,
      false,
      influence
    );
    clothSoftBody.appendAnchor(
      clothNumSegmentsZ * (clothNumSegmentsZ + 1) + clothNumSegmentsZ,
      arm2.userData.physicsBody,
      false,
      influence
    );
  }

  animate() {
    if (this.cloth.material.map) this.cloth.material.map.needsUpdate = true;
    if (this.face.material.map) this.face.material.map.needsUpdate = true;
    let deltaTime = clock.getDelta();
    if (this.needUpdatePhysics) this.updatePhysics(deltaTime);
    this.renderer.render(this.scene, this.camera);
  }

  async updatePhysics(deltaTime) {
    this.physicsWorld.stepSimulation(deltaTime, 10);
    const cloth = this.cloth;
    let softBody = cloth.userData.physicsBody;
    let clothPositions = cloth.geometry.attributes.position.array;
    let numVerts = clothPositions.length / 3;
    let nodes = softBody.get_m_nodes();
    let indexFloat = 0;
    for (let i = 0; i < numVerts; i++) {
      let node = nodes.at(i);
      let nodePos = node.get_m_x();
      clothPositions[indexFloat++] = nodePos.x();
      clothPositions[indexFloat++] = nodePos.y();
      clothPositions[indexFloat++] = nodePos.z();
    }
    cloth.geometry.computeVertexNormals();
    cloth.geometry.attributes.position.needsUpdate = true;
    cloth.geometry.attributes.normal.needsUpdate = true;

    for (let i = 0, il = this.rigidBodies.length; i < il; i++) {
      let objThree = this.rigidBodies[i];
      let objPhys = objThree.userData.physicsBody;
      let ms = objPhys.getMotionState();
      if (ms) {
        ms.getWorldTransform(this.transformAux1);
        let p = this.transformAux1.getOrigin();
        let q = this.transformAux1.getRotation();
        objThree.position.set(p.x(), p.y(), p.z());
        objThree.quaternion.set(q.x(), q.y(), q.z(), q.w());
      }
    }
  }

  createParalellepiped(
    sx,
    sy,
    sz,
    mass,
    pos,
    quat,
    material,
    addOnScreen = true
  ) {
    var threeObject = new THREE.Mesh(
      new THREE.BoxBufferGeometry(sx, sy, sz, 1, 1, 1),
      material
    );
    var shape = new this.Ammo_.btBoxShape(
      new this.Ammo_.btVector3(sx * 0.5, sy * 0.5, sz * 0.5)
    );
    shape.setMargin(margin);

    this.createRigidBody(threeObject, shape, mass, pos, quat, addOnScreen);

    return threeObject;
  }

  createRigidBody(threeObject, physicsShape, mass, pos, quat, addOnScreen) {
    threeObject.position.copy(pos);
    threeObject.quaternion.copy(quat);

    var transform = new this.Ammo_.btTransform();
    transform.setIdentity();
    transform.setOrigin(new this.Ammo_.btVector3(pos.x, pos.y, pos.z));
    transform.setRotation(
      new this.Ammo_.btQuaternion(quat.x, quat.y, quat.z, quat.w)
    );
    var motionState = new this.Ammo_.btDefaultMotionState(transform);

    var localInertia = new this.Ammo_.btVector3(0, 0, 0);
    physicsShape.calculateLocalInertia(mass, localInertia);

    var rbInfo = new this.Ammo_.btRigidBodyConstructionInfo(
      mass,
      motionState,
      physicsShape,
      localInertia
    );
    var body = new this.Ammo_.btRigidBody(rbInfo);

    threeObject.userData.physicsBody = body;
    if (addOnScreen) this.scene.add(threeObject);

    // if (mass > 0) {
    this.rigidBodies.push(threeObject);

    // Disable deactivation
    body.setActivationState(4);
    // console.log(body);
    // }

    this.physicsWorld.addRigidBody(body);
  }

  render() {
    return (
      <div
        id="container"
        ref={(ref) => (this.container = ref)}
        style={{ width: "100%", height: "100%" }}
      ></div>
    );
  }
}
