import React from "react";
import { Modal } from "react-bootstrap";

export default ({ onHide, src, callToAction }) => {
  return (
    <Modal
      dialogClassName="modal-rounded"
      show={true}
      onHide={onHide}
      centered={true}
    >
      <Modal.Body>
        <div>
          <div className="d-flex align-items-end justify-content-end">
            <div className="p-3" onClick={onHide}>
              <img src="/assets/images/close.svg" height={16} />
            </div>
          </div>
          <div className="flex-1 text-muted bd-placeholder-img card-img-top bg-complement rounded mb-3 iframe-video">
            <iframe
              width="100%"
              height="100%"
              src={src}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          <div
            className="btn btn-primary btn-lg w-100  mt-4"
            onClick={callToAction}
          >
            Quero fazer minha análise!
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
