import React from "react";
import Question from "../../components/question/Question";
import { Link } from "react-router-dom";
import { useQuestion } from "../../hooks/useQuestion";
import ColorQuestion from "../../components/question/ColorQuestion";
import QuestionImagesTumbler from "../../components/question/QuestionImagesTumbler";

export const RenderQuestion = ({
  question,
  answerQuestion,
  hasBack,
  goBack,
  hasNext,
  goNext,
  idQuiz,
}) => {
  if (!question) return <p></p>;
  if (question.type == "colorQuestion")
    return (
      <ColorQuestion
        question={question}
        answerQuestion={answerQuestion}
        goBack={hasBack ? goBack : null}
        goNext={hasNext ? goNext : null}
        productId={idQuiz}
      />
    );

  if (question.type == "twoOptions")
    return (
      <div class="container py-3">
        <QuestionImagesTumbler
          question={question}
          answerQuestion={answerQuestion}
          goBack={hasBack ? goBack : null}
          goNext={hasNext ? goNext : null}
          productId={idQuiz}
        />
      </div>
    );

  return (
    <div class="container py-3">
      <Question
        question={question}
        answerQuestion={answerQuestion}
        goBack={hasBack ? goBack : null}
        goNext={hasNext ? goNext : null}
        productId={idQuiz}
      />
    </div>
  );
};
export default ({ match: { params } }) => {
  const { idQuiz, idQuestion } = params;

  const {
    question,
    answerQuestion,
    goBack,
    hasBack,
    hasNext,
    goNext,
  } = useQuestion(idQuiz, idQuestion);

  return (
    <RenderQuestion
      {...{
        question,
        answerQuestion,
        goBack,
        hasBack,
        hasNext,
        goNext,
        idQuiz,
        idQuestion,
      }}
    />
  );
};
