import React, { useEffect, useState } from "react";
import useCurrentUser from "../../hooks/useCurrentUser";
import { useSiteConfigReload } from "../../hooks/useSiteConfig";
import { db } from "../../base";
import { addSuccessNotification } from "../../Notifications";
import MaskedInput from "react-text-mask";

export default ({
  className,
  title,
  buttonStyle,
  onSuccess,
  buttonText = "Salvar alterações",
  valid = (currentUser, gender, cellphone, instagram) =>
    currentUser.gender != gender ||
    currentUser.instagram != instagram ||
    currentUser.cellphone != cellphone,
}) => {
  const currentUser = useCurrentUser();
  const reload = useSiteConfigReload();
  const [gender, setGender] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [instagram, setInstagram] = useState("@");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser.gender) setGender(currentUser.gender);
    if (currentUser.cellphone) setCellphone(currentUser.cellphone);
    if (currentUser.instagram) setInstagram(currentUser.instagram);
  }, [currentUser]);

  const save = () => {
    setLoading(true);
    db.collection("users")
      .doc(currentUser.id)
      .update({
        gender,
        cellphone,
        instagram,
      })
      .then((_) => {
        reload();
        addSuccessNotification("Salvo", "Informações salvas com sucesso");
        setLoading(false);
        if (onSuccess) onSuccess();
      });
  };

  const enable = () => {
    return valid(currentUser, gender, cellphone, instagram);
  };
  return (
    <div class={`card mb-4 shadow-sm rounded nt ${className}`}>
      <div class={`card-body bg-white p-0 custom-menu`}>
        {title}
        <div className="form-group">
          <label>Nome</label>
          <input
            className="form-control bg-white"
            readOnly
            value={currentUser.displayName}
          />
        </div>
        {currentUser.email && (
          <div className="form-group">
            <label>E-mail</label>
            <input
              className="form-control bg-white"
              readOnly
              value={currentUser.email}
            />
          </div>
        )}
        <div className="form-group">
          <label>Gênero que mais se indentifica</label>
          <select
            className="form-control"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
          >
            <option value={""}>-</option>
            <option value={"feminino"}>Feminino</option>
            <option value={"masculino"}>Masculino</option>
            <option value={"outro"}>Outro</option>
          </select>
        </div>
        <div className="form-group">
          <label>Celular</label>
          <MaskedInput
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              ")",
              " ",
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={cellphone}
            onChange={(e) => setCellphone(e.target.value)}
            className="form-control bg-white"
          />
        </div>
        <div className="form-group">
          <label>Instagram</label>
          <input
            className="form-control bg-white"
            onChange={(e) => setInstagram(e.target.value)}
            value={instagram}
          />
        </div>
        <div>
          <button
            className="btn btn-primary float-right"
            onClick={save}
            style={{
              height: 40,
              borderRadius: 8,
              opacity: enable() ? 1 : 0.5,
              ...buttonStyle,
            }}
            disabled={!enable() || loading}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
