import React, { useEffect } from "react";

export default class FaceSkin extends React.Component {
  constructor() {
    super();
    this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    this.props.registerAnimate(this.animate);
  }

  updateCanvas(canvas) {
    this.faceCanvas = canvas;
  }
  async animate() {
    //
    if (!this.faceCanvas) return;

    const toImage = (base64) => {
      return new Promise((r) => {
        var image = new Image();
        image.src = base64;
        image.onload = () => r(image);
      });
    };

    this.processImg(await toImage(this.faceCanvas.toDataURL("image/png")));
  }

  processImg(img) {
    const canvas = this.canvas;
    const { width, height } = img;

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, width, height);

    var data = imageData.data;
    let alphaY = 1;
    let rSum = 0;
    let gSum = 0;
    let bSum = 0;
    let sum = 0;
    const histogram = {};
    for (var i = 0; i < data.length; i += 4) {
      const r = data[i] + 50;
      const g = data[i + 1] + 25;
      const b = data[i + 2] + 25;

      const Y = Math.max(0, 0.2989 * r + 0.5866 * g + 0.1145 * b); //+ 0;
      const CB = Math.max(0, -0.1688 * r - 0.3312 * g + 0.5 * b) + 128;
      const CR = Math.max(0, 0.5 * r - 0.4184 * g - 0.0816 * b) + 128;
      //   & (CB > 77) && CB < 127 && CR > 133 && CR < 173

      if (data[i + 3] > 0)
        histogram[`${r}-${b}-${g}`] = histogram[`${r}-${b}-${g}`]
          ? histogram[`${r}-${b}-${g}`] + 1
          : 1;

      if (r != b && b != g && g != r)
        if (Y > 110 && CB > 77 && CR > 133 && data[i + 3] != 0) {
          sum++;
          data[i] = r;
          data[i + 1] = g;
          data[i + 2] = b;
          rSum += data[i + 0];
          gSum += data[i + 1];
          bSum += data[i + 2];
        } else {
          data[i + 3] = 0;
        }
    }

    // const result = Object.keys(histogram)
    //   .map((key) => ({ key: key, value: histogram[key] }))
    //   .sort((a, b) => b.value - a.value);

    // if (result.length > 0) {
    //   const [{ value: maxValue }] = result;

    //   let keys = result
    //     .filter((f) => f.value >= maxValue * 0.5)
    //     .map((e) => e.key);

    //   for (var i = 0; i < data.length; i += 4) {
    //     const r = data[i] + 50;
    //     const g = data[i + 1] + 25;
    //     const b = data[i + 2] + 25;

    //     if (!keys.includes(`${r}-${b}-${g}`)) {
    //       data[i + 3] = 0;
    //     }
    //     // if (r != b && b != g && g != r)
    //     //   if (Y > 110 && CB > 77 && CR > 133 && data[i + 3] != 0) {
    //     //     sum++;
    //     //     data[i] = r;
    //     //     data[i + 1] = g;
    //     //     data[i + 2] = b;
    //     //     rSum += data[i + 0];
    //     //     gSum += data[i + 1];
    //     //     bSum += data[i + 2];
    //     //   } else {
    //     //     data[i + 3] = 0;
    //     //   }
    //   }
    // }

    if (sum > 0) {
      const ctx2 = this.canvas2.getContext("2d");
      ctx2.strokeStyle = `rgb(${rSum / sum}, ${gSum / sum}, ${bSum / sum})`;
      ctx2.fillStyle = `rgb(${rSum / sum}, ${gSum / sum}, ${bSum / sum})`;
      ctx2.fillRect(0, 0, width, height);
      ctx2.stroke();
    }
    ctx.putImageData(imageData, 0, 0);
  }

  render() {
    return (
      <div>
        <canvas
          ref={(ref) => (this.canvas = ref)}
          style={{
            height: "100px",
            width: "100px",
            border: "1px solid black",
          }}
        />
        <canvas
          ref={(ref) => (this.canvas2 = ref)}
          style={{
            height: "100px",
            width: "100px",
            border: "1px solid black",
          }}
        />
      </div>
    );
  }
}
