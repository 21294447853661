import React, { useEffect, useState } from "react";
import "./admin.scss";
import { db } from "../../../base";

function useProduct(productId) {
  const [product, setProduct] = useState();

  useEffect(() => {
    db.collection("products")
      .doc(productId)
      .get()
      .then((product) => {
        setProduct(product.data());
      });
  }, []);

  return product;
}
function useSaveProduct(productId) {
  return (data) => {
    console.log(data);
    db.collection("products").doc(productId).update(data);
  };
}

export default ({ idQuiz }) => {
  const product = useProduct(idQuiz);
  const saveProduct = useSaveProduct(idQuiz);

  const fullProduct = {
    resultName: "string",
    buttonText: "string",
    price: "number",
    displayPrice: "string",
    sort: "number",
    description: "string",
    image: "string",
    reponseResult: "string",
    prerequisite: {
      productId: "string",
      button: "string",
      text: "string",
      title: "string",
    },
    intro: {
      video: "string",
      button: "string",
      title: "string",
      text: "string",
    },
    about: {
      video: "string",
    },
    type: "string",
    productName: "string",
    name: "string",
  };

  const [productEdited, setProductEdited] = useState(product);
  const [lastChange, setLastChange] = useState("");

  useEffect(() => {
    setProductEdited(product);
    setLastChange(JSON.stringify(product));
  }, [product]);

  const save = () => {
    saveProduct(productEdited);
    setLastChange(JSON.stringify(productEdited));
  };

  if (!product) return null;

  const changeItem = (textPath, obj, value) => {
    const pathSplited = textPath.split(".");

    let element = obj;
    if (!obj) return undefined;
    for (let i = 0; i < pathSplited.length; i++) {
      const currentPath = pathSplited[i];

      if (i == pathSplited.length - 1) {
        element[currentPath] = value;
        break;
      }
      element = element[currentPath];
      if (!element) break;
    }
    return obj;
  };

  const getItem = (textPath, obj) => {
    const pathSplited = textPath.split(".");

    let element = obj;
    if (!obj) return undefined;
    for (let i = 0; i < pathSplited.length; i++) {
      const currentPath = pathSplited[i];
      element = element[currentPath];
      if (!element) break;
    }
    return element;
  };

  const register = (name) => {
    return {
      value: getItem(name, productEdited) || "",
      onChange: (e) => {
        const event = e;
        event.preventDefault();
        const value = {
          number: +event.target.value,
          string: event.target.value,
          // boolean:
        }[getItem(name, fullProduct)];
        if (value)
          setProductEdited((obj) =>
            changeItem(name, JSON.parse(JSON.stringify(obj)), value)
          );
      },
    };
  };

  const itemToArr = (key, item) => {
    if (typeof item !== "object") return { key, type: item };

    return {
      key,
      itens: Object.keys(item).map((k) => itemToArr(k, item[k])),
      type: "array",
    };
  };

  const itens = Object.keys(fullProduct)
    .map((key) => itemToArr(key, fullProduct[key]))
    .sort((a, b) => (a.itens ? 1 : -1));

  // console.log(productEdited);

  const renderItem = (item, parentKey) => {
    // console.log(item);
    if (item.type == "array")
      return (
        <div className="form-group">
          <b>{item.key}</b>
          <div className="pl-2">
            {item.itens.map((i) => renderItem(i, item.key))}
          </div>
          <hr />
        </div>
      );
    return (
      <div className="form-group">
        <label>{item.key}</label>
        <input
          className="form-control"
          {...register(parentKey ? `${parentKey}.${item.key}` : item.key)}
        />
      </div>
    );
  };
  // console.log(itens);

  return (
    <div className="card card-shadow">
      <div className="card-body">
        <h5 class="card-title">Dados do produto</h5>
        {itens.map((item) => renderItem(item))}

        <button
          disabled={JSON.stringify(productEdited) == lastChange}
          className="btn btn-primary w-100"
          onClick={save}
        >
          Salvar
        </button>
      </div>
    </div>
  );
};
