import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCzycRBJODiUu-3FPRQrHgdQ0ahtiZg3Vo",
  authDomain: "mayfaggion.zeero.tech",
  databaseURL: "https://online-consulting-c8c25.firebaseio.com",
  projectId: "online-consulting-c8c25",
  storageBucket: "online-consulting-c8c25.appspot.com",
  messagingSenderId: "237421908625",
  appId: "1:237421908625:web:188e82b590afc4ff382530",
  measurementId: "G-DTD6ZVWQCV",
});

export const db = firebase.firestore();
export const storage = firebase.storage();
firebase.analytics();
export default app;
