import React, { useRef, useEffect } from "react";

export default ({ meshUpdated, setCanvas, colors }) => {
  const canvas = useRef(null);
  const canvasCopy = useRef(null);

  const drawMesh = (colors) => {
    const ctx = canvas.current.getContext("2d");
    canvas.current.height = 1200;
    canvas.current.width = 1200;
    const { width, height } = canvas.current;

    const center = [height / 2, width / 2];

    const drawTriangle = (color, start, end) => {
      ctx.beginPath();
      ctx.moveTo(...start);
      ctx.lineTo(...center);
      ctx.lineTo(...end);
      ctx.closePath();
      ctx.fillStyle = color;
      ctx.fill();
    };

    const [c1, c2, c3, c4] = colors;
    drawTriangle(c1, [0, 0], [0, height]);
    drawTriangle(c2, [0, 0], [width, 0]);
    drawTriangle(c3, [width, 0], [width, height]);
    drawTriangle(c4, [0, height], [width, height]);

    if (meshUpdated) meshUpdated();
  };

  useEffect(() => {
    if (setCanvas) setCanvas(canvasCopy.current);
    if (colors) drawMesh(colors);
  }, [canvas]);

  useEffect(() => {
    drawMesh(colors);
    coppy();
  }, [colors]);

  const coppy = () => {
    var mapCanvas = canvasCopy.current;
    mapCanvas.width = mapCanvas.height = canvas.current.width;

    // document.body.appendChild( mapCanvas );
    var ctx = mapCanvas.getContext("2d");
    var TO_RADIANS = Math.PI / 180;
    ctx.rotate(90 * TO_RADIANS);
    ctx.drawImage(canvas.current, 0, -canvas.current.width);
    // ctx.drawImage(canvas.current, 0, -canvas.current.width);
    // ctx.rotate(90);
  };
  return (
    <div>
      <canvas ref={canvas} style={{ height: 100, width: 100 }}></canvas>
      <canvas
        ref={canvasCopy}
        style={{ display: "none", height: 100, width: 100 }}
      ></canvas>
    </div>
  );
};
