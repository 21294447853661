import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import QuizFinish from "../../Quiz/QuizFinish";
import { db } from "../../../../base";
import Question from "../../../../components/question/Question";
import QuizFinish from "../../../../components/quiz/QuizFinish";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { RenderQuestion } from "../../../Quiz/Question";
function useResult({ match = {} }) {
  const { productId, questionid } = match.params || {};
  const [result, setResut] = useState();

  useState(() => {
    db.collection("products")
      .doc(productId)
      .collection("questions")
      .doc(questionid)
      .get()
      .then((i) => i.data())
      .then((r) => setResut(r));
  }, []);
  return result;
}

export default (props) => {
  const question = useResult(props);
  const { match = {} } = props;
  const { productId, questionid } = match.params || {};
  const history = useHistory();
  const itemRef = useRef(null);

  useEffect(() => {
    document.body.className += " no-scroll ";

    return () => {
      document.body.className = document.body.className.replaceAll(
        " no-scroll ",
        ""
      );
    };
  }, []);
  const close = () => {
    history.push(`/adm/${productId}`);
  };

  const objToOption = (obj) => {
    return Object.keys(obj).map((key) => (
      <option key={key} value={key}>
        {obj[key]}
      </option>
    ));
  };

  useOutsideAlerter(itemRef, close);
  if (!question) return null;
  return (
    <div class="modal fade show" style={{ display: "block" }}>
      <div class="modal-dialog" role="document" ref={itemRef}>
        <div
          class="modal-content"
          style={{
            overflow: "auto",
            maxHeight: "90vh",
          }}
        >
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              {questionid}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            style={{
              background: "black",
              width: "100%",
              height: 1,
            }}
          ></div>
          <div class="modal-body">
            <RenderQuestion
              question={question}
              answerQuestion={() => false}
              //   goBack={hasBack ? goBack : null}
              //   goNext={hasNext ? goNext : null}
              productId={productId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
