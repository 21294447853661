import React from "react";
import Value from "../../components/app-config/Value";

export default () => {
  return (
    <div class="container py-3">
      <div className=" row justify-content-center d-flex align-items-center">
        <div className="col-lg-6">
          <div class="card mb-4 shadow-sm rounded nt ">
            <div class={`card-body bg-white`}>
              <Value
                className="card-text mb-2 text-black font-weight-bold  font-2 mb-0 "
                field="help-instagram-title"
              />
              <Value
                className="card-text text-second-black font-2 mb-3 "
                field="help-instagram-description"
              />

              <a
                href={
                  "instagram.com.br/mayfaggionmailto:consultoriamayfaggion@gmail.com"
                }
                className="btn btn-primary btn-lg w-100 rounded mt-4"
              >
                Ver conteúdo
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" row justify-content-center d-flex align-items-center">
        <div className="col-lg-6">
          <div class="card mb-4 shadow-sm rounded nt ">
            <div class={`card-body bg-white`}>
              <Value
                className="card-text mb-2 text-black font-weight-bold  font-2 mb-0 "
                field="help-title"
              />
              <Value
                className="card-text text-second-black font-2 mb-3 "
                field="help-description"
              />
              <div
                class="background-image bd-placeholder-img card-img-top bg-bg-complement rounded"
                style={{
                  height: 120,
                  backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/online-consulting-c8c25.appspot.com/o/help-me.png?alt=media)`,
                }}
              ></div>
              <a
                href={"mailto:consultoriamayfaggion@gmail.com"}
                className="btn btn-primary btn-lg w-100 rounded mt-4"
              >
                Conversar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
