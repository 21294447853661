import { store } from "react-notifications-component";

export function addSuccessNotification(title, message) {
  store.addNotification({
    title,
    message,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated ", "animate__slideInDown "],
    animationOut: ["animate__animated ", "animate__fadeOut "],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}
export function addWarningNotification(title, message) {
  store.addNotification({
    title,
    message,
    type: "warning",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated ", "animate__slideInDown "],
    animationOut: ["animate__animated ", "animate__fadeOut "],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}
